import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Slider,
  Switch,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import {
  Pause,
  PlayArrow,
} from "@material-ui/icons";
import './PoseAnimation.css'
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import useWindowSize from "../hooks/useWindowSize";
import { IonSelect, IonSelectOption } from "@ionic/react";

const poseCoordinateSpaceY = 1848;
const poseCoordinateSpaceX = 3264;

const theme = createTheme({
  palette: {
    primary: {
      main: "#4aa47c"
    }
  },
});

const useStyles = makeStyles((theme) => ({
  poseDiv: {
    maxHeight: "350px",
    minHeight: "200px",
    maxWidth: "100%",
    marginLeft: "0px",
    marginRight: "0px",
    display: "flex",
  },
  poseCanvas: {
    display: "flex",
    maxHeight: "350px",
    maxWidth: "100%",
    marginLeft: "0px",
    marginRight: "0px",
    width: "100%",
    border: "solid 2px red",
    borderColor: "red",
    borderRadius: '5px'
  },
  poseCont: {
    // margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 120,
    marginRight: 'auto',
    root: {
      "& Mui-disabled": {
        color: "green"
      }
    },
  },
  formControlLabel: {
    root: {
      "& Mui-disabled": {
        color: "green"
      }
    },
    marginLeft: 0,
    marginRight: 'auto',
  },
  iconButtons: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  inputLabelFix: {
    backgroundColor: "white",
    paddingLeft: 2,
    paddingRight: 2,
  },
  input: {
    display: "none",
  },
  progressBar: {
    width: "80%",
    height: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  playControlsWrapper: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    paddingTop: "0rem",
  },
}));

let acontroller: AbortController = new AbortController();
let signal: AbortSignal = acontroller.signal;

const PoseAnimation: React.FC<PoseAnimationProps> = ({ data, showing }) => {
  const fps: number = 7.6923;

  const classes = useStyles();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const screenSize = useWindowSize();

  // states
  const [poseSeries, setPoseSeries] = useState<any[]>([]);
  const [keypoints, setKeypoints] = useState<Set<string | undefined>>();
  const [drawablePosesArray, setDrawablePosesArray] = useState<Map<string, any[]>>();
  const [elapsed, setElapsed] = useState<number>(0);
  const [elapsedFrames, setElapsedFrames] = useState<number>(0);
  const [running, setRunning] = useState(false);
  const [totalFrames, setTotalFrames] = useState<number>(0);
  const [drawJoints, setDrawJoints] = useState(true);
  const [speed, setSpeed] = useState<number>(0.5);
  // const [filename, setFilename] = useState<string>("");
  // const [progress, setProgress] = useState<number>(0);
  const [repeat, setRepeat] = useState(false);
  const [poseCanvasHeight, setPoseCanvasHeight] = useState<number>(200);
  const [poseCanvasWidth, setPoseCanvasWidth] = useState<number>(350);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const KEYPOINTS: string[] = [
    "nose",
    "left_eye",
    "right_eye",
    "left_ear",
    "right_ear",
    "left_shoulder",
    "right_shoulder",
    "left_elbow",
    "right_elbow",
    "left_wrist",
    "right_wrist",
    "left_hip",
    "right_hip",
    "left_knee",
    "right_knee",
    "left_ankle",
    "right_ankle",
  ];

  //if user closes modal, we need to end the loop
  useEffect(() => {
    if (!showing) {
      setRunning(false);
      acontroller.abort();
      console.log("CALL ABORT");
    }

    return () => {
      setRunning(false);
      acontroller.abort();
      console.log("CALL ABORT");
    }
  }, [showing]);

  useEffect(() => {
    if (poseSeries) {
      let poseArray = new Map<string, any[]>();
      const keypointsArr = [];
      let frames = 0;

      const testi = Object.entries(poseSeries);

      for (let [key, value] of testi) {
        const keyValueArr = Object.values(value);
        //poseArray[key] = keyValueArr;
        poseArray.set(key, keyValueArr);

        keypointsArr.push(key);
        frames = keyValueArr.length;
      }

      // get unique keypoints
      const keypoints2 = new Set(
        keypointsArr.map((keypoint) => keypoint.slice(1))
      );

      setKeypoints(keypoints2);
      setDrawablePosesArray(poseArray);
      setTotalFrames(frames);

      setCanvasSizeToState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poseSeries]);

  useLayoutEffect(() => {
    const start = async () => {
      await sleep(100);
      startAnimation();
    };

    if(!running && drawablePosesArray && drawablePosesArray?.size > 0){
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawablePosesArray]);

  const sleep = (time: number = 100) => {
    //console.log(time);
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  // draw a line
  const drawLine = (ctx: any, info: any, style: any = {}) => {
    const { x, y, x1, y1 } = info;
    const { color = "black", width = 1 } = style;

    if (!ctx) {
      acontroller.abort();
      return;
    }

    if (x === -1 || y === -1 || x1 === -1 || y1 === -1) {
      return;
    }

    ctx.beginPath();
    ctx.moveTo(scalerX(x), scalerY(y));
    ctx.lineTo(scalerX(x1), scalerY(y1));
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.closePath();
    ctx.stroke();
  };

  // draw a dot
  const drawDot = (
    ctx: any,
    x: number,
    y: number,
    fillColor = "blue",
    dotSize = 2
  ) => {
    if (!ctx) {
      acontroller.abort();
      return;
    }

    //draw a dot
    ctx.beginPath();
    ctx.arc(scalerX(x), scalerY(y), dotSize, 0, Math.PI * dotSize, true);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
  };

  const clearCanvas = (ctx: CanvasRenderingContext2D) => {
    if (ctx) {
      ctx.save();
      ctx.globalCompositeOperation = "copy";
      ctx.strokeStyle = "transparent";
      ctx.beginPath();
      ctx.lineTo(0, 0);
      ctx.stroke();
      ctx.restore();
    }
  };

  const startAnimation = async (startFrame = 0) => {
    acontroller = new AbortController();
    signal = acontroller.signal;

    await animate(startFrame);
  };

  const scalerX = (val: number) => {
    // camera view 3264x1848;
    const ctx = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;

    const curW: number = ctx?.canvas.width; //canvasRef.current?.width;

    return Math.ceil(val * (curW / poseCoordinateSpaceX));
  };

  const scalerY = (val: number) => {
    // camera view
    const ctx = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;
    const curH: number = ctx?.canvas.height; //canvasRef.current?.width;

    return Math.ceil(val * (curH / poseCoordinateSpaceY));
  };

  const setCanvasSizeToState = () => {
    const poseCanvasScale = Math.ceil(
      poseCoordinateSpaceX / poseCoordinateSpaceY
    );
    setPoseCanvasWidth(screenSize.width); //X
    setPoseCanvasHeight(Math.ceil(screenSize.width / poseCanvasScale)); //Y = X / scale
  };

  const animate = async (startFrame: number) => {
    setRunning(true);
    //setCanvasSizeToState();
    const sleeptime: number = 1000 / fps;

    const ctx = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;

    clearCanvas(ctx);

    for (let i: number = startFrame ? startFrame : elapsedFrames; i <= totalFrames; i++) {
      if (signal.aborted) {
        setRunning(false);
        // console.log("ANIMATION ABORTED");
        return true;
      }

      // draw points for every keypoint
      keypoints &&
        keypoints.forEach((keypoint) => {
          if (typeof drawablePosesArray?.get("x" + keypoint) === "object") {

            const wipeWidth = 6;
            const jointWidth = 6;
            const jointColor = "black";

            // remove previous joints
            if (i > 0 && drawJoints) {
              // remove previous arms
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft elbow")![i - 1], //RETHINK
                  y: drawablePosesArray.get("yleft elbow")![i - 1],
                  x1: drawablePosesArray.get("xleft wrist")![i - 1],
                  y1: drawablePosesArray.get("yleft wrist")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright elbow")![i - 1],
                  y: drawablePosesArray.get("yright elbow")![i - 1],
                  x1: drawablePosesArray.get("xright wrist")![i - 1],
                  y1: drawablePosesArray.get("yright wrist")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous upper arms
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft elbow")![i - 1],
                  y: drawablePosesArray.get("yleft elbow")![i - 1],
                  x1: drawablePosesArray.get("xleft shoulder")![i - 1],
                  y1: drawablePosesArray.get("yleft shoulder")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright elbow")![i - 1],
                  y: drawablePosesArray.get("yright elbow")![i - 1],
                  x1: drawablePosesArray.get("xright shoulder")![i - 1],
                  y1: drawablePosesArray.get("yright shoulder")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous shoulders
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright shoulder")![i - 1],
                  y: drawablePosesArray.get("yright shoulder")![i - 1],
                  x1: drawablePosesArray.get("xleft shoulder")![i - 1],
                  y1: drawablePosesArray.get("yleft shoulder")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous hip
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i - 1],
                  y: drawablePosesArray.get("yright hip")![i - 1],
                  x1: drawablePosesArray.get("xleft hip")![i - 1],
                  y1: drawablePosesArray.get("yleft hip")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous upper legs
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft hip")![i - 1],
                  y: drawablePosesArray.get("yleft hip")![i - 1],
                  x1: drawablePosesArray.get("xleft knee")![i - 1],
                  y1: drawablePosesArray.get("yleft knee")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i - 1],
                  y: drawablePosesArray.get("yright hip")![i - 1],
                  x1: drawablePosesArray.get("xright knee")![i - 1],
                  y1: drawablePosesArray.get("yright knee")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous calfs
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft ankle")![i - 1],
                  y: drawablePosesArray.get("yleft ankle")![i - 1],
                  x1: drawablePosesArray.get("xleft knee")![i - 1],
                  y1: drawablePosesArray.get("yleft knee")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright ankle")![i - 1],
                  y: drawablePosesArray.get("yright ankle")![i - 1],
                  x1: drawablePosesArray.get("xright knee")![i - 1],
                  y1: drawablePosesArray.get("yright knee")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );

              // remove previous body
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft hip")![i - 1],
                  y: drawablePosesArray.get("yleft hip")![i - 1],
                  x1: drawablePosesArray.get("xleft shoulder")![i - 1],
                  y1: drawablePosesArray.get("yleft shoulder")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i - 1],
                  y: drawablePosesArray.get("yright hip")![i - 1],
                  x1: drawablePosesArray.get("xright shoulder")![i - 1],
                  y1: drawablePosesArray.get("yright shoulder")![i - 1],
                },
                { color: "white", width: wipeWidth }
              );
            }

            // draw joints
            if (i > 0 && drawJoints) {
              // arms
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft elbow")![i],
                  y: drawablePosesArray.get("yleft elbow")![i],
                  x1: drawablePosesArray.get("xleft wrist")![i],
                  y1: drawablePosesArray.get("yleft wrist")![i],
                },
                { color: jointColor, width: jointWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright elbow")![i],
                  y: drawablePosesArray.get("yright elbow")![i],
                  x1: drawablePosesArray.get("xright wrist")![i],
                  y1: drawablePosesArray.get("yright wrist")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // upper arms
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft elbow")![i],
                  y: drawablePosesArray.get("yleft elbow")![i],
                  x1: drawablePosesArray.get("xleft shoulder")![i],
                  y1: drawablePosesArray.get("yleft shoulder")![i],
                },
                { color: jointColor, width: jointWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright elbow")![i],
                  y: drawablePosesArray.get("yright elbow")![i],
                  x1: drawablePosesArray.get("xright shoulder")![i],
                  y1: drawablePosesArray.get("yright shoulder")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // shoulders
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright shoulder")![i],
                  y: drawablePosesArray.get("yright shoulder")![i],
                  x1: drawablePosesArray.get("xleft shoulder")![i],
                  y1: drawablePosesArray.get("yleft shoulder")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // hip
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i],
                  y: drawablePosesArray.get("yright hip")![i],
                  x1: drawablePosesArray.get("xleft hip")![i],
                  y1: drawablePosesArray.get("yleft hip")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // upper legs
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft hip")![i],
                  y: drawablePosesArray.get("yleft hip")![i],
                  x1: drawablePosesArray.get("xleft knee")![i],
                  y1: drawablePosesArray.get("yleft knee")![i],
                },
                { color: jointColor, width: jointWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i],
                  y: drawablePosesArray.get("yright hip")![i],
                  x1: drawablePosesArray.get("xright knee")![i],
                  y1: drawablePosesArray.get("yright knee")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // calf
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft ankle")![i],
                  y: drawablePosesArray.get("yleft ankle")![i],
                  x1: drawablePosesArray.get("xleft knee")![i],
                  y1: drawablePosesArray.get("yleft knee")![i],
                },
                { color: jointColor, width: jointWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright ankle")![i],
                  y: drawablePosesArray.get("yright ankle")![i],
                  x1: drawablePosesArray.get("xright knee")![i],
                  y1: drawablePosesArray.get("yright knee")![i],
                },
                { color: jointColor, width: jointWidth }
              );

              // body
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xleft hip")![i],
                  y: drawablePosesArray.get("yleft hip")![i],
                  x1: drawablePosesArray.get("xleft shoulder")![i],
                  y1: drawablePosesArray.get("yleft shoulder")![i],
                },
                { color: jointColor, width: jointWidth }
              );
              drawLine(
                ctx,
                {
                  x: drawablePosesArray.get("xright hip")![i],
                  y: drawablePosesArray.get("yright hip")![i],
                  x1: drawablePosesArray.get("xright shoulder")![i],
                  y1: drawablePosesArray.get("yright shoulder")![i],
                },
                { color: jointColor, width: jointWidth }
              );
            }

            if (i > 0) {
              // remove previous dot
              const xPrev = drawablePosesArray.get("x" + keypoint)![i - 1];
              const yPrev = drawablePosesArray.get("y" + keypoint)![i - 1];
              //drawDot(ctx, scaler(xPrev), scaler(yPrev), "white", scaler(4));
              drawDot(ctx, xPrev, yPrev, "white", 8);
            }
            // get keypoint values
            const x = drawablePosesArray.get("x" + keypoint)![i];
            const y = drawablePosesArray.get("y" + keypoint)![i];
            //console.log("drawablePoses DOTS " + keypoint, x, y);
            //drawDot(ctx, scaler(x), scaler(y), "red", scaler(2));
            drawDot(ctx, x, y, "red", 4);
          }
        }); //foreach all keypoits loop

      setElapsedFrames(i);

      // setProgress((i / totalFrames) * 100);

      if (i % 1 === 0) {
        // frames to elapsed
        const elapSeconds = i / fps;

        const newValue = Math.round(elapSeconds);

        if (newValue !== elapsed) {
          setElapsed(newValue);
        }
      }
      // wait a while
      await sleep(sleeptime / speed);
    }


    // after animation decide to loop or not
    if (repeat) {
      await sleep(1000);
      startAnimation(0);
    } else {
      setElapsedFrames(0);
      setRunning(false);
    }
  };

  useEffect(() => {
    const parseData = () => {
      const resJson = JSON.parse(data);
      //console.log("GOT Json DATA ", resJson);

      const parsedPose: any = {
        xnose: {},
        ynose: {},
        "xleft eye": {},
        "yleft eye": {},
        "xright eye": {},
        "yright eye": {},
        "xleft ear": {},
        "yleft ear": {},
        "xright ear": {},
        "yright ear": {},
        "xleft shoulder": {},
        "yleft shoulder": {},
        "xright shoulder": {},
        "yright shoulder": {},
        "xleft elbow": {},
        "yleft elbow": {},
        "xright elbow": {},
        "yright elbow": {},
        "xleft wrist": {},
        "yleft wrist": {},
        "xright wrist": {},
        "yright wrist": {},
        "xleft hip": {},
        "yleft hip": {},
        "xright hip": {},
        "yright hip": {},
        "xleft knee": {},
        "yleft knee": {},
        "xright knee": {},
        "yright knee": {},
        "xleft ankle": {},
        "yleft ankle": {},
        "xright ankle": {},
        "yright ankle": {},
      };

      const keypoints: any = [
        { xnose: { index: 0, coor: 0 } },
        { ynose: { index: 0, coor: 1 } },
        { "xleft eye": { index: 1, coor: 0 } },
        { "yleft eye": { index: 1, coor: 1 } },
        { "xright eye": { index: 2, coor: 0 } },
        { "yright eye": { index: 2, coor: 1 } },
        { "xleft ear": { index: 3, coor: 0 } },
        { "yleft ear": { index: 3, coor: 1 } },
        { "xright ear": { index: 4, coor: 0 } },
        { "yright ear": { index: 4, coor: 1 } },
        { "xleft shoulder": { index: 5, coor: 0 } },
        { "yleft shoulder": { index: 5, coor: 1 } },
        { "xright shoulder": { index: 6, coor: 0 } },
        { "yright shoulder": { index: 6, coor: 1 } },
        { "xleft elbow": { index: 7, coor: 0 } },
        { "yleft elbow": { index: 7, coor: 1 } },
        { "xright elbow": { index: 8, coor: 0 } },
        { "yright elbow": { index: 8, coor: 1 } },
        { "xleft wrist": { index: 9, coor: 0 } },
        { "yleft wrist": { index: 9, coor: 1 } },
        { "xright wrist": { index: 10, coor: 0 } },
        { "yright wrist": { index: 10, coor: 1 } },
        { "xleft hip": { index: 11, coor: 0 } },
        { "yleft hip": { index: 11, coor: 1 } },
        { "xright hip": { index: 12, coor: 0 } },
        { "yright hip": { index: 12, coor: 1 } },
        { "xleft knee": { index: 13, coor: 0 } },
        { "yleft knee": { index: 13, coor: 1 } },
        { "xright knee": { index: 14, coor: 0 } },
        { "yright knee": { index: 14, coor: 1 } },
        { "xleft ankle": { index: 15, coor: 0 } },
        { "yleft ankle": { index: 15, coor: 1 } },
        { "xright ankle": { index: 16, coor: 0 } },
        { "yright ankle": { index: 16, coor: 1 } },
      ];

      const poseArr: any[] = Object.values(resJson);
      //console.log("poseArr ", poseArr);

      for (const keypoint of keypoints) {
        const keypointName: string = Object.keys(keypoint)[0];
        const keypointIndex: number = keypoint[keypointName].index;
        const keypointCoor: number = keypoint[keypointName].coor;

        for (const [idx, poseItem] of Object.entries(poseArr)) {
          if (poseItem) {
            const poseScore = poseItem[0][keypointIndex][1];
            if (poseScore && poseScore > 0) {
              parsedPose[keypointName][idx] =
                poseItem[0][keypointIndex][0][keypointCoor];
            }
          }
        }
      }

      //console.log("parsedPose ", parsedPose);
      setPoseSeries(parsedPose);
    };

    parseData();
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resetAnimation = () => {
    setElapsedFrames(0);
    setElapsed(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSliderCommit = async (e: any, value: number) => {
    const wasRunning = running;
    acontroller.abort();

    await sleep(200);
    // setProgress(prog * 100)
    setElapsedFrames(value);
    // frames to elapsed
    const elapSeconds = value / fps;
    const newValue = Math.round(elapSeconds);
    setElapsed(newValue);
    await sleep(300);
    if (wasRunning) {
      startAnimation(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const progressBarClick = async (e: any) => {
    acontroller.abort();

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    // const y = e.clientY - rect.top; //y position within the element.

    const rectW = rect.width;
    const prog: number = x / rectW;

    await sleep(200);
    // setProgress(prog * 100);
    setElapsedFrames(totalFrames * prog);
    // frames to elapsed
    const elapSeconds = (totalFrames * prog) / fps;
    const newValue = Math.round(elapSeconds);
    setElapsed(newValue);
    await sleep(300);
    startAnimation(totalFrames * prog);
  };

  //useEffect(() => {}, []);

  //QUESSING....
  //const disableSlider = !poseSeries["xleft elbow"];
  //const disableControls = running || !poseSeries["xleft elbow"];
  const disableSlider = false; //drawablePosesArray && drawablePosesArray.get("xleft elbow")![i - 1]; //!poseSeries[5] && !poseSeries[6]; // "xleft shoulder" | "xright shoulder" == 5 & 6
  const disableControls = running; //running || !poseSeries[5];

  return (
    <ThemeProvider theme={theme}>
      <Grid container classes={{ root: classes.poseCont }}>

        <Grid item xs={6} sm={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              control={
                <Switch
                  checked={drawJoints}
                  onChange={(e) => setDrawJoints(e.target.checked)}
                  name="checkedB"
                  color="primary"
                  disabled={disableControls}
                />
              }
              label="Piirrä raajat"
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              control={
                <Switch
                  checked={repeat}
                  onChange={(e) => setRepeat(e.target.checked)}
                  color="primary"
                  disabled={disableControls}
                />
              }
              label="Looppi"
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={4}>
          <FormControl variant="outlined" color="primary" className={classes.formControl}>
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              control={
                <IonSelect
                  id="speed-label"
                  value={speed}
                  disabled={disableControls}
                  cancelText="Peruuta"
                  okText="Hyväksy"
                  color="primary"
                  onIonChange={(e: any) => setSpeed(e.target.value)}
                >
                  <IonSelectOption value={0.5} color="primary">
                    0.5
                  </IonSelectOption>
                  <IonSelectOption value={1} color="primary">
                    1.0
                  </IonSelectOption>
                  <IonSelectOption value={2} color="primary">
                    2.0
                  </IonSelectOption>
                  <IonSelectOption value={10} color="primary">
                    10.0
                  </IonSelectOption>
                </IonSelect>
              }
              label="Nopeus"
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        {/* Poseanimation canvas */}
        <Grid item>
          <Paper className={classes.poseDiv}>
            {
              <canvas
                height={poseCanvasHeight}
                width={poseCanvasWidth}
                className="pose-style"
                ref={canvasRef}
              ></canvas>
            }
          </Paper>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={2} sm={1}>
            <FormControl className={classes.iconButtons}>
              {!running ? (
                <IconButton
                  aria-label="delete"
                  color="primary"
                  disabled={disableControls}
                  onClick={() => startAnimation()}
                >
                  <PlayArrow />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => acontroller.abort()}
                >
                  <Pause />
                </IconButton>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Slider
              value={elapsedFrames}
              aria-labelledby="discrete-slider-always"
              disabled={disableSlider}
              min={0}
              max={totalFrames}
              valueLabelDisplay="on"
              valueLabelFormat={(x) => Math.round(x / fps)}
            />
          </Grid>
          {/*           <Grid item xs={2}>
            <div className={classes.iconButtons}>
              {elapsed}/{Math.round(totalFrames / fps)}
            </div>
          </Grid> */}
        </Grid>

        <Grid item xs={12}>Kulunut aika {elapsed} s</Grid>
      </Grid>
    </ThemeProvider>
  );
};

interface PoseAnimationProps {
  data: string;
  showing: boolean;
}

export default PoseAnimation;
