import { useIonToast } from "@ionic/react"
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../state/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector



// toast hooks
export const useResultToast = () => {
    const [present] = useIonToast()
    return (msg: string, toastType: "success" | "warning" | "danger") =>  present({message: msg, color: toastType, duration: 3000})
}