import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react'
import { statsChart, statsChartOutline, person, personOutline, settings, settingsOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import { Route, Redirect } from 'react-router'
import Events from '../pages/Events'
import Manage from '../pages/Manage'
import Settings from '../pages/Settings'
import { AmplifyUser } from '../types/kamuTypes'
import EventsTabBadge from './EventsTabBadge'

const TabsRoot: React.FC<ITabsRoot> = ({ user }) => {

    const [activeTabName, setActiveTabName] = useState<string | null>(null)

    return (
        <IonTabs onIonTabsWillChange={item => setActiveTabName(item.detail.tab)}>
            <IonRouterOutlet>
                <Route path="/events" component={Events} exact />
                <Route path="/settings" component={Settings} exact />
                <Route path="/manage" component={Manage} exact />
                <Route path="/" render={() => <Redirect to="/events" />} exact />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="events" href="/events">
                    <EventsTabBadge />
                    <IonIcon icon={activeTabName === "events" ? statsChart : statsChartOutline} />
                    <IonLabel>Tapahtumat</IonLabel>
                </IonTabButton>
                <IonTabButton tab="settings" href="/settings">
                    <IonIcon icon={activeTabName === "settings" ? person : personOutline} />
                    <IonLabel>Omat asetukset</IonLabel>
                </IonTabButton>
                {user && (user.username?.includes("@heinolasoftwares.fi") || user.username?.includes("@aisoft.fi") || user.username?.includes("miika.jukakoski")) &&
                    <IonTabButton tab="manage" href="/manage">
                        <IonIcon icon={activeTabName === "manage" ? settings : settingsOutline} />
                        <IonLabel>Laitehallinta</IonLabel>
                    </IonTabButton>
                }
            </IonTabBar>
        </IonTabs>
    )
}

export default TabsRoot

interface ITabsRoot {
    user: AmplifyUser | undefined
}