/* Using with IonModal Component */

import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonInput, IonItem, IonItemDivider, IonList, IonButtons, IonLabel, IonListHeader, IonAlert, IonLoading, IonToast } from '@ionic/react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { GetUserQuery, GetDeviceQuery, CreateUserDeviceMutation } from '../API';
import { getDevice } from '../graphql/queries';
import { mapGetUserQuery, mapGetDeviceQuery, mapCreateUserDeviceMutation } from '../graphql/queryMapping';
import callGraphQL from '../models/graphql-api';
import { getUserAndDevices } from '../graphql/queriesCustom';
import { createUserDevice2 } from '../graphql/mutationsCustom';
import { useStore } from '../store/store';

const AddDeviceToUserModal: React.FC<AddDeviceToUserModalProps> = ({ openModal }) => {

  const dispatch = useStore(true)[1]

  const [showModal, setShowModal] = useState(false);
  const [deviceId, setDeviceId] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [toastContent, setToastContent] = useState('')
  const [errorMsg, setErrorMsg] = useState('')


  useEffect(() => {
    if (openModal) {
      setShowModal(true)
    }
  }, [openModal])

  const handleDeviceAdd = async () => {

    setShowLoading(true)

    try {
      // get current user's cognito id
      const authUser = await Auth.currentAuthenticatedUser()

      // get current user's userdata
      const resUser = await callGraphQL<GetUserQuery>(getUserAndDevices, { id: authUser.attributes.sub });
      const user = mapGetUserQuery(resUser)

      // check if the device exist and not already registered
      const resDevice = await callGraphQL<GetDeviceQuery>(getDevice, { id: deviceId });
      const device = mapGetDeviceQuery(resDevice)

      if (!device.id) {
        // device doesn't exist
        setErrorMsg(`Laitetta ei löydy rekisteristä<br><br>id: ${deviceId}`);
        setShowAlert1(true)
        setShowLoading(false)

        return false
      }

      console.log(user)

      // check if device already registered
      if (user.devices?.items) {
        for (let regDev of user?.devices?.items) {
          if (regDev?.device?.id === deviceId) {
            setErrorMsg(`Laite on jo sinulla seurannassa<br><br>id: ${deviceId}`);
            setShowAlert1(true)
            setShowLoading(false)

            return false
          }
        }
      }

      // register device for user
      const res = await callGraphQL<CreateUserDeviceMutation>(createUserDevice2, { input: { userId: user.id, deviceId: deviceId } });
      // update state on successfull mutation
      const updatedUser = mapCreateUserDeviceMutation(res);
      dispatch("SET_CURRENT_USER", updatedUser)

      setShowLoading(false)
      setToastContent('Laite rekisteröity')
      setDeviceId('')
      setShowToast1(true)
      setShowModal(false)

    } catch (error) {
     console.log('Error adding device : ', JSON.stringify(error))
      let msg = ""
      // @ts-ignore
      error.errors && error.errors.forEach((err:any) => {
        msg += ": " + err.message
        })
      console.log('Error adding device message ', msg) //remove message after pilot, just "error adding"
      setErrorMsg('Virhe lisättäessä laitetta ' + msg)
      setShowAlert1(true)
      setShowLoading(false)
    }

  };

  if (showModal) {
    return <IonModal isOpen={showModal} animated backdropDismiss={false} swipeToClose={true}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Lisää seurattava laite</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContentStyled >

        <IonList>
          <IonListHeader lines="inset">
            <IonLabel>Syötä laitteen id-numero alla olevaan kenttään</IonLabel>
          </IonListHeader>
          <InputLabel>Laitteen id</InputLabel>
          <IonItemStyled>
            <IonInput value={deviceId} placeholder="Syötä laitteen id-numero..." onIonChange={e => setDeviceId(e.detail.value!)}></IonInput>
          </IonItemStyled>

        </IonList>
      </IonContentStyled>
      <IonToolbar>
        <IonButtons slot="primary">
          <IonButton color="medium" onClick={() => setShowModal(false)}>Peruuta</IonButton>
          <IonButton disabled={deviceId?.length === 0} onClick={handleDeviceAdd}>Hyväksy</IonButton>
        </IonButtons>
      </IonToolbar>
      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        /*         cssClass="my-custom-class" */
        header={"Virhe!"}
        message={errorMsg}
        buttons={["OK"]}
      />
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={toastContent}
        duration={1500}
      />
      <IonLoading
        /*         cssClass='my-custom-class' */
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Odota..."}
        duration={5000}
      />

    </IonModal>
  } else {
    return null
  }
};

export default AddDeviceToUserModal


const InputLabel = styled(IonItemDivider)`
  width: 100%;
`

const IonItemStyled = styled(IonItem)`
  width: 100%;
`

const IonContentStyled = styled(IonContent)`
  // --background: green;
  --padding-top: 1rem;
  --padding-end: 1rem;
  --padding-start: 1rem;
`

interface AddDeviceToUserModalProps {
  openModal: boolean
}
