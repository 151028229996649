/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type LambdaResponse = {
  __typename: "LambdaResponse",
  statusCode?: number | null,
  body?: StepStartBody,
};

export type StepStartBody = {
  __typename: "StepStartBody",
  executionArn?: string | null,
  startDate?: string | null,
};

export type AbortStepFunctionResponse = {
  __typename: "AbortStepFunctionResponse",
  statusCode?: number | null,
  body?: StepAbortBody,
};

export type StepAbortBody = {
  __typename: "StepAbortBody",
  stopDate?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  username: string,
  fcmId?: Array< string | null > | null,
  notificationsOn?: boolean | null,
  status: userStatus,
  admin?: boolean | null,
  groupIDs?: Array< string | null > | null,
};

export enum userStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  BUSY = "BUSY",
  PASSIVE = "PASSIVE",
}


export type ModelUserConditionInput = {
  username?: ModelStringInput | null,
  fcmId?: ModelStringInput | null,
  notificationsOn?: ModelBooleanInput | null,
  status?: ModeluserStatusInput | null,
  admin?: ModelBooleanInput | null,
  groupIDs?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModeluserStatusInput = {
  eq?: userStatus | null,
  ne?: userStatus | null,
};

export type User = {
  __typename: "User",
  id?: string,
  username?: string,
  fcmId?: Array< string | null > | null,
  notificationsOn?: boolean | null,
  status?: userStatus,
  admin?: boolean | null,
  groupIDs?: Array< string | null > | null,
  createdAt?: string,
  updatedAt?: string,
  devices?: ModelUserDeviceConnection,
};

export type ModelUserDeviceConnection = {
  __typename: "ModelUserDeviceConnection",
  items?:  Array<UserDevice | null > | null,
  nextToken?: string | null,
};

export type UserDevice = {
  __typename: "UserDevice",
  id?: string,
  userId?: string,
  deviceId?: string,
  createdAt?: string,
  updatedAt?: string,
  user?: User,
  device?: Device,
};

export type Device = {
  __typename: "Device",
  id?: string,
  deviceType?: DeviceType | null,
  name?: string | null,
  description?: string | null,
  status?: KamuStatus | null,
  alarmStatus?: KamuAlarmStatus | null,
  owner?: string | null,
  ownerId?: string | null,
  pairingActive?: boolean | null,
  address?: string | null,
  groupID?: string | null,
  createdAt?: string,
  updatedAt?: string,
  followers?: ModelUserDeviceConnection,
  events?: ModelEventConnection,
};

export enum DeviceType {
  KAMU = "KAMU",
  VOKA = "VOKA",
}


export enum KamuStatus {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  ERROR = "ERROR",
}


export enum KamuAlarmStatus {
  ON = "ON",
  OFF = "OFF",
}


export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type Event = {
  __typename: "Event",
  id?: string,
  eventID?: string,
  status?: eventStatus | null,
  predClass?: eventClasses | null,
  targetClass?: eventClasses | null,
  ackTime?: string | null,
  ackUserId?: string | null,
  data?: string | null,
  content?: string,
  freeForm?: string | null,
  deviceID?: string,
  groupID?: string | null,
  reservedByUserId?: string | null,
  reservedUntil?: string | null,
  stepFunctionID?: string | null,
  geoLocation?: string | null,
  createdAt?: string,
  updatedAt?: string,
  ackUser?: User,
  reservedByUser?: User,
  device?: Device,
};

export enum eventStatus {
  ALARM_ACTIVE = "ALARM_ACTIVE",
  ALARM_CANCELLED = "ALARM_CANCELLED",
  ALARM_ACKNOWLEDGED = "ALARM_ACKNOWLEDGED",
  ALARM_DONE = "ALARM_DONE",
  CALL_FOR_ASSISTANCE_ACTIVE = "CALL_FOR_ASSISTANCE_ACTIVE",
  CALL_FOR_ASSISTANCE_CANCELLED = "CALL_FOR_ASSISTANCE_CANCELLED",
  CALL_FOR_ASSISTANCE_ACKNOWLEDGED = "CALL_FOR_ASSISTANCE_ACKNOWLEDGED",
  CALL_FOR_ASSISTANCE_DONE = "CALL_FOR_ASSISTANCE_DONE",
}


export enum eventClasses {
  FALL = "FALL",
  SHOE_TYING = "SHOE_TYING",
  VOICE_CALL = "VOICE_CALL",
  BUTTON_CALL = "BUTTON_CALL",
  PERSON_DETECTED = "PERSON_DETECTED",
  UNAUTHORIZED_PERSON_DETECTED = "UNAUTHORIZED_PERSON_DETECTED",
  SMOKE_ALARM_DETECTED = "SMOKE_ALARM_DETECTED",
  INACTIVITY = "INACTIVITY",
  FALSE_ALARM = "FALSE_ALARM",
  SPORT_OR_SOMETHING = "SPORT_OR_SOMETHING",
}


export type UpdateUserInput = {
  id: string,
  username?: string | null,
  fcmId?: Array< string | null > | null,
  notificationsOn?: boolean | null,
  status?: userStatus | null,
  admin?: boolean | null,
  groupIDs?: Array< string | null > | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateDeviceInput = {
  id?: string | null,
  deviceType?: DeviceType | null,
  name?: string | null,
  description?: string | null,
  status?: KamuStatus | null,
  alarmStatus?: KamuAlarmStatus | null,
  owner?: string | null,
  ownerId?: string | null,
  pairingActive?: boolean | null,
  address?: string | null,
  groupID?: string | null,
};

export type ModelDeviceConditionInput = {
  deviceType?: ModelDeviceTypeInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelKamuStatusInput | null,
  alarmStatus?: ModelKamuAlarmStatusInput | null,
  owner?: ModelStringInput | null,
  ownerId?: ModelStringInput | null,
  pairingActive?: ModelBooleanInput | null,
  address?: ModelStringInput | null,
  groupID?: ModelStringInput | null,
  and?: Array< ModelDeviceConditionInput | null > | null,
  or?: Array< ModelDeviceConditionInput | null > | null,
  not?: ModelDeviceConditionInput | null,
};

export type ModelDeviceTypeInput = {
  eq?: DeviceType | null,
  ne?: DeviceType | null,
};

export type ModelKamuStatusInput = {
  eq?: KamuStatus | null,
  ne?: KamuStatus | null,
};

export type ModelKamuAlarmStatusInput = {
  eq?: KamuAlarmStatus | null,
  ne?: KamuAlarmStatus | null,
};

export type UpdateDeviceInput = {
  id: string,
  deviceType?: DeviceType | null,
  name?: string | null,
  description?: string | null,
  status?: KamuStatus | null,
  alarmStatus?: KamuAlarmStatus | null,
  owner?: string | null,
  ownerId?: string | null,
  pairingActive?: boolean | null,
  address?: string | null,
  groupID?: string | null,
};

export type DeleteDeviceInput = {
  id: string,
};

export type CreateUserDeviceInput = {
  id?: string | null,
  userId: string,
  deviceId: string,
};

export type ModelUserDeviceConditionInput = {
  userId?: ModelIDInput | null,
  deviceId?: ModelIDInput | null,
  and?: Array< ModelUserDeviceConditionInput | null > | null,
  or?: Array< ModelUserDeviceConditionInput | null > | null,
  not?: ModelUserDeviceConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateUserDeviceInput = {
  id: string,
  userId?: string | null,
  deviceId?: string | null,
};

export type DeleteUserDeviceInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  eventID: string,
  status?: eventStatus | null,
  predClass?: eventClasses | null,
  targetClass?: eventClasses | null,
  ackTime?: string | null,
  ackUserId?: string | null,
  data?: string | null,
  content: string,
  freeForm?: string | null,
  deviceID: string,
  groupID?: string | null,
  reservedByUserId?: string | null,
  reservedUntil?: string | null,
  stepFunctionID?: string | null,
  geoLocation?: string | null,
  createdAt?: string | null,
};

export type ModelEventConditionInput = {
  eventID?: ModelIDInput | null,
  status?: ModeleventStatusInput | null,
  predClass?: ModeleventClassesInput | null,
  targetClass?: ModeleventClassesInput | null,
  ackTime?: ModelStringInput | null,
  ackUserId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  content?: ModelStringInput | null,
  freeForm?: ModelStringInput | null,
  deviceID?: ModelIDInput | null,
  groupID?: ModelStringInput | null,
  reservedByUserId?: ModelIDInput | null,
  reservedUntil?: ModelStringInput | null,
  stepFunctionID?: ModelIDInput | null,
  geoLocation?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModeleventStatusInput = {
  eq?: eventStatus | null,
  ne?: eventStatus | null,
};

export type ModeleventClassesInput = {
  eq?: eventClasses | null,
  ne?: eventClasses | null,
};

export type UpdateEventInput = {
  id: string,
  eventID?: string | null,
  status?: eventStatus | null,
  predClass?: eventClasses | null,
  targetClass?: eventClasses | null,
  ackTime?: string | null,
  ackUserId?: string | null,
  data?: string | null,
  content?: string | null,
  freeForm?: string | null,
  deviceID?: string | null,
  groupID?: string | null,
  reservedByUserId?: string | null,
  reservedUntil?: string | null,
  stepFunctionID?: string | null,
  geoLocation?: string | null,
  createdAt?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  fcmId?: ModelStringInput | null,
  notificationsOn?: ModelBooleanInput | null,
  status?: ModeluserStatusInput | null,
  admin?: ModelBooleanInput | null,
  groupIDs?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type ModelDeviceFilterInput = {
  id?: ModelIDInput | null,
  deviceType?: ModelDeviceTypeInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelKamuStatusInput | null,
  alarmStatus?: ModelKamuAlarmStatusInput | null,
  owner?: ModelStringInput | null,
  ownerId?: ModelStringInput | null,
  pairingActive?: ModelBooleanInput | null,
  address?: ModelStringInput | null,
  groupID?: ModelStringInput | null,
  and?: Array< ModelDeviceFilterInput | null > | null,
  or?: Array< ModelDeviceFilterInput | null > | null,
  not?: ModelDeviceFilterInput | null,
};

export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items?:  Array<Device | null > | null,
  nextToken?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  status?: ModeleventStatusInput | null,
  predClass?: ModeleventClassesInput | null,
  targetClass?: ModeleventClassesInput | null,
  ackTime?: ModelStringInput | null,
  ackUserId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  content?: ModelStringInput | null,
  freeForm?: ModelStringInput | null,
  deviceID?: ModelIDInput | null,
  groupID?: ModelStringInput | null,
  reservedByUserId?: ModelIDInput | null,
  reservedUntil?: ModelStringInput | null,
  stepFunctionID?: ModelIDInput | null,
  geoLocation?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type StartReservationHandlerStepFunctionMutationVariables = {
  deviceID?: string,
  eventID?: string,
  releaseDT?: string,
};

export type StartReservationHandlerStepFunctionMutation = {
  startReservationHandlerStepFunction?:  {
    __typename: "LambdaResponse",
    statusCode?: number | null,
    body?:  {
      __typename: "StepStartBody",
      executionArn?: string | null,
      startDate?: string | null,
    } | null,
  } | null,
};

export type AbortStepFunctionMutationVariables = {
  stepFunctionArn?: string,
};

export type AbortStepFunctionMutation = {
  abortStepFunction?:  {
    __typename: "AbortStepFunctionResponse",
    statusCode?: number | null,
    body?:  {
      __typename: "StepAbortBody",
      stopDate?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input?: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input?: UpdateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input?: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserDeviceMutationVariables = {
  input?: CreateUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type CreateUserDeviceMutation = {
  createUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserDeviceMutationVariables = {
  input?: UpdateUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type UpdateUserDeviceMutation = {
  updateUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserDeviceMutationVariables = {
  input?: DeleteUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type DeleteUserDeviceMutation = {
  deleteUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateEventMutationVariables = {
  input?: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateEventMutationVariables = {
  input?: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteEventMutationVariables = {
  input?: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUser2MutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUser2Mutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        device:  {
          __typename: "Device",
          id: string,
          deviceType?: DeviceType | null,
          name?: string | null,
          owner?: string | null,
          address?: string | null,
          alarmStatus?: KamuAlarmStatus | null,
          createdAt: string,
          description?: string | null,
          ownerId?: string | null,
          status?: KamuStatus | null,
          updatedAt: string,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserDevice2MutationVariables = {
  input?: CreateUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type CreateUserDevice2Mutation = {
  createUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        items?:  Array< {
          __typename: "UserDevice",
          id: string,
          device:  {
            __typename: "Device",
            id: string,
            name?: string | null,
            owner?: string | null,
            address?: string | null,
            alarmStatus?: KamuAlarmStatus | null,
            createdAt: string,
            description?: string | null,
            ownerId?: string | null,
            status?: KamuStatus | null,
            updatedAt: string,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceQueryVariables = {
  id?: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListDevicesQueryVariables = {
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items?:  Array< {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id?: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      eventID: string,
      status?: eventStatus | null,
      predClass?: eventClasses | null,
      targetClass?: eventClasses | null,
      ackTime?: string | null,
      ackUserId?: string | null,
      data?: string | null,
      content: string,
      freeForm?: string | null,
      deviceID: string,
      groupID?: string | null,
      reservedByUserId?: string | null,
      reservedUntil?: string | null,
      stepFunctionID?: string | null,
      geoLocation?: string | null,
      createdAt: string,
      updatedAt: string,
      ackUser?:  {
        __typename: "User",
        id: string,
        username: string,
        fcmId?: Array< string | null > | null,
        notificationsOn?: boolean | null,
        status: userStatus,
        admin?: boolean | null,
        groupIDs?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      reservedByUser?:  {
        __typename: "User",
        id: string,
        username: string,
        fcmId?: Array< string | null > | null,
        notificationsOn?: boolean | null,
        status: userStatus,
        admin?: boolean | null,
        groupIDs?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      device:  {
        __typename: "Device",
        id: string,
        deviceType?: DeviceType | null,
        name?: string | null,
        description?: string | null,
        status?: KamuStatus | null,
        alarmStatus?: KamuAlarmStatus | null,
        owner?: string | null,
        ownerId?: string | null,
        pairingActive?: boolean | null,
        address?: string | null,
        groupID?: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type EventByDeviceByDateQueryVariables = {
  deviceID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventByDeviceByDateQuery = {
  eventByDeviceByDate?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      eventID: string,
      status?: eventStatus | null,
      predClass?: eventClasses | null,
      targetClass?: eventClasses | null,
      ackTime?: string | null,
      ackUserId?: string | null,
      data?: string | null,
      content: string,
      freeForm?: string | null,
      deviceID: string,
      groupID?: string | null,
      reservedByUserId?: string | null,
      reservedUntil?: string | null,
      stepFunctionID?: string | null,
      geoLocation?: string | null,
      createdAt: string,
      updatedAt: string,
      ackUser?:  {
        __typename: "User",
        id: string,
        username: string,
        fcmId?: Array< string | null > | null,
        notificationsOn?: boolean | null,
        status: userStatus,
        admin?: boolean | null,
        groupIDs?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      reservedByUser?:  {
        __typename: "User",
        id: string,
        username: string,
        fcmId?: Array< string | null > | null,
        notificationsOn?: boolean | null,
        status: userStatus,
        admin?: boolean | null,
        groupIDs?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      device:  {
        __typename: "Device",
        id: string,
        deviceType?: DeviceType | null,
        name?: string | null,
        description?: string | null,
        status?: KamuStatus | null,
        alarmStatus?: KamuAlarmStatus | null,
        owner?: string | null,
        ownerId?: string | null,
        pairingActive?: boolean | null,
        address?: string | null,
        groupID?: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDevicesJoinEventsQueryVariables = {
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevicesJoinEventsQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items?:  Array< {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          eventID: string,
          status?: eventStatus | null,
          predClass?: eventClasses | null,
          targetClass?: eventClasses | null,
          freeForm?: string | null,
          data?: string | null,
          content: string,
          deviceID: string,
          createdAt: string,
          updatedAt: string,
          stepFunctionID?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserAndDevicesQueryVariables = {
  id?: string,
};

export type GetUserAndDevicesQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        device:  {
          __typename: "Device",
          id: string,
          deviceType?: DeviceType | null,
          name?: string | null,
          owner?: string | null,
          address?: string | null,
          alarmStatus?: KamuAlarmStatus | null,
          createdAt: string,
          description?: string | null,
          ownerId?: string | null,
          status?: KamuStatus | null,
          updatedAt: string,
        },
      } | null > | null,
    } | null,
  } | null,
};

export type GetUserAndDevicesAndEventsQueryVariables = {
  id?: string,
};

export type GetUserAndDevicesAndEventsQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        device:  {
          __typename: "Device",
          id: string,
          deviceType?: DeviceType | null,
          name?: string | null,
          owner?: string | null,
          address?: string | null,
          alarmStatus?: KamuAlarmStatus | null,
          createdAt: string,
          description?: string | null,
          ownerId?: string | null,
          status?: KamuStatus | null,
          updatedAt: string,
          events?:  {
            __typename: "ModelEventConnection",
            items?:  Array< {
              __typename: "Event",
              createdAt: string,
              content: string,
              id: string,
              eventID: string,
              predClass?: eventClasses | null,
              freeForm?: string | null,
              status?: eventStatus | null,
              targetClass?: eventClasses | null,
              ackTime?: string | null,
              ackUser?:  {
                __typename: "User",
                username: string,
              } | null,
              reservedByUser?:  {
                __typename: "User",
                username: string,
              } | null,
              reservedByUserId?: string | null,
              reservedUntil?: string | null,
              stepFunctionID?: string | null,
              geoLocation?: string | null,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
    } | null,
  } | null,
};

export type OnUpdateByUserIdSubscriptionVariables = {
  id?: string,
};

export type OnUpdateByUserIdSubscription = {
  onUpdateByUserId?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnEventCreateOrUpdateSubscriptionVariables = {
  deviceID?: string | null,
};

export type OnEventCreateOrUpdateSubscription = {
  onEventCreateOrUpdate?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnEventCreateOrUpdateByGroupSubscriptionVariables = {
  groupID?: string | null,
};

export type OnEventCreateOrUpdateByGroupSubscription = {
  onEventCreateOrUpdateByGroup?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    admin?: boolean | null,
    groupIDs?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?:  {
    __typename: "Device",
    id: string,
    deviceType?: DeviceType | null,
    name?: string | null,
    description?: string | null,
    status?: KamuStatus | null,
    alarmStatus?: KamuAlarmStatus | null,
    owner?: string | null,
    ownerId?: string | null,
    pairingActive?: boolean | null,
    address?: string | null,
    groupID?: string | null,
    createdAt: string,
    updatedAt: string,
    followers?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        userId: string,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        eventID: string,
        status?: eventStatus | null,
        predClass?: eventClasses | null,
        targetClass?: eventClasses | null,
        ackTime?: string | null,
        ackUserId?: string | null,
        data?: string | null,
        content: string,
        freeForm?: string | null,
        deviceID: string,
        groupID?: string | null,
        reservedByUserId?: string | null,
        reservedUntil?: string | null,
        stepFunctionID?: string | null,
        geoLocation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserDeviceSubscription = {
  onCreateUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserDeviceSubscription = {
  onUpdateUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserDeviceSubscription = {
  onDeleteUserDevice?:  {
    __typename: "UserDevice",
    id: string,
    userId: string,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    },
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    eventID: string,
    status?: eventStatus | null,
    predClass?: eventClasses | null,
    targetClass?: eventClasses | null,
    ackTime?: string | null,
    ackUserId?: string | null,
    data?: string | null,
    content: string,
    freeForm?: string | null,
    deviceID: string,
    groupID?: string | null,
    reservedByUserId?: string | null,
    reservedUntil?: string | null,
    stepFunctionID?: string | null,
    geoLocation?: string | null,
    createdAt: string,
    updatedAt: string,
    ackUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    reservedByUser?:  {
      __typename: "User",
      id: string,
      username: string,
      fcmId?: Array< string | null > | null,
      notificationsOn?: boolean | null,
      status: userStatus,
      admin?: boolean | null,
      groupIDs?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    device:  {
      __typename: "Device",
      id: string,
      deviceType?: DeviceType | null,
      name?: string | null,
      description?: string | null,
      status?: KamuStatus | null,
      alarmStatus?: KamuAlarmStatus | null,
      owner?: string | null,
      ownerId?: string | null,
      pairingActive?: boolean | null,
      address?: string | null,
      groupID?: string | null,
      createdAt: string,
      updatedAt: string,
      followers?:  {
        __typename: "ModelUserDeviceConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateByUserId2SubscriptionVariables = {
  id?: string,
};

export type OnUpdateByUserId2Subscription = {
  onUpdateByUserId?:  {
    __typename: "User",
    id: string,
    username: string,
    fcmId?: Array< string | null > | null,
    notificationsOn?: boolean | null,
    status: userStatus,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelUserDeviceConnection",
      items?:  Array< {
        __typename: "UserDevice",
        id: string,
        device:  {
          __typename: "Device",
          id: string,
          deviceType?: DeviceType | null,
          name?: string | null,
          owner?: string | null,
          address?: string | null,
          alarmStatus?: KamuAlarmStatus | null,
          createdAt: string,
          description?: string | null,
          ownerId?: string | null,
          status?: KamuStatus | null,
          updatedAt: string,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};
