import React, { useEffect, useState } from "react";
import "./CaptureQRPage.css";
import {
  IonButton,
  IonText,
  useIonAlert,
} from "@ionic/react";
import {
  BarcodeScanner,
  SupportedFormat,
} from "@capacitor-community/barcode-scanner";

let timer: NodeJS.Timeout;

const CaptureQRPage = (props: any) => {

  const [presentAlarm] = useIonAlert();
  const [shutdownCount, setShutdownCount] = useState(10);

  const closeScanner = async () => {
    props.closeScanner();
  };

  const scan = async () => {
    try {
      BarcodeScanner.hideBackground();

      document.body.style.background = "transparent";

      const data = await BarcodeScanner.startScan({
        targetedFormats: [SupportedFormat.QR_CODE],
      });

      if (data.hasContent && data.content) {
        console.log("BarcodeScanner content: ", data);
        const deviceObj = JSON.parse(data.content);

        console.log(
          "BarcodeScanner deviceObj: ",
          deviceObj.type,
          deviceObj.name
        );
        if (deviceObj.type !== "kamu" && deviceObj.type !== "voka") {
          throw Error("Type not valid");
        }

        if (!deviceObj.name) {
          throw Error("Name not defined");
        }

        await props.onHandleDeviceAdd(deviceObj.name); //should this be ID?

        await closeScanner();

      }
    } catch (error) {
      console.log("BarcodeScanner error: ", error);

      // @ts-ignore
      presentAlarm({
        message: error,
        header: "Virhe",
        buttons: [
          {
            text: "Ok",
            handler: () => {
              console.log("Confirm Ok");
            },
          },
        ],
      });

      await closeScanner();
    }
  };

  useEffect(() => {
    // open scanner
    scan();

    timer = setInterval(() => {
      if (shutdownCount > 0) {
        setShutdownCount((prev) => prev - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

      <div className="container-qr">
        <div className="barcode-scanner--area--container">
          <div className="relative ion-text-center" >
            <IonText color="light">
              Osoita kameralla laitteen QR-koodia
            </IonText>
          </div>
          <div className="square surround-cover">
            <div className="barcode-scanner--area--outer surround-cover">
              <div className="barcode-scanner--area--inner"></div>
            </div>
          </div>
          <div className="relative">
            <IonButton
              fill="outline"
              size="default"
              expand="block"
              color="light"
              onClick={closeScanner}
            >
              Sulje
            </IonButton>
          </div>
          <div>{shutdownCount}</div>
        </div>
      </div>

  );
};

export default CaptureQRPage;
