import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GetUserQuery, GetUserQueryVariables, UpdateUserMutation, UpdateUserMutationVariables, User } from '../API'
import { RootState } from './store'
import { updateUser as updateUserMut } from '../graphql/mutations'
import callGraphQL from '../models/graphql-api'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getUser } from '../graphql/queries'

// Define a type for the slice state
interface UserState {
    user: User | null
    status: 'idle' | 'pending' | 'fulfilled' | 'failed'
    error: string | null | undefined | [graphqlError]
}

// Define the initial state using that type
const initialState: UserState = {
    user: null,
    status: 'idle',
    error: null
}

function getUserQuery(getUserQuery: GraphQLResult<GetUserQuery>): User | null {
    if (getUserQuery.data?.getUser) {
        return getUserQuery.data.getUser as User
    } else {
        return null
    }
}

// fetch user
export const fetchUser = createAsyncThunk<User | null, GetUserQueryVariables, { rejectValue: GeneralGraphqlError }>(
    'user/getUser',
    async (getUserInput, { rejectWithValue }) => {
        try {
            const resUser = await callGraphQL<GetUserQuery>(getUser, getUserInput)
            const user = getUserQuery(resUser)
            return user
        } catch (error) {
            const gqlError = error as GeneralGraphqlError
            return rejectWithValue(gqlError)
        }
    }
)


export type GeneralGraphqlError = {
    data: {} | null
    errors: [graphqlError] | null
}

type graphqlError = {
    message: string
    errorType: string
}



function updateUserMutation(updateUserMutation: GraphQLResult<UpdateUserMutation>): User {
    return { ...updateUserMutation.data?.updateUser } as User || {}
}

export const updateUser = createAsyncThunk<User, UpdateUserMutationVariables, { rejectValue: GeneralGraphqlError }>(
    'user/updateUser',
    async (userUpdateInput, { rejectWithValue }) => {
        try {
            const resUser = await callGraphQL<UpdateUserMutation>(updateUserMut, userUpdateInput);
            return updateUserMutation(resUser)
        } catch (error) {
            const gqlError = error as GeneralGraphqlError
            return rejectWithValue(gqlError)
        }
    }
)



export const usersSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        doSomething: (state, action: PayloadAction<String>) => {
            console.log('vehicle removed')
            // state.users -= 1
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(fetchUser.fulfilled, (state, action) => {
                // fetch all devices
                state.status = 'fulfilled'
                state.user = action.payload
            })
            .addCase(fetchUser.pending, (state, action) => {
                state.status = 'pending'
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                // update fulfilled user
                state.user = action.payload
            })
            .addCase(updateUser.rejected, (state, action) => {
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    state.error = action.payload.errors
                } else {
                    state.error = action.error.message
                }
            })
    }
})

export const { doSomething } = usersSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUsers = (state: RootState) => state.user

export default usersSlice.reducer
