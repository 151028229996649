import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import React, { } from "react";
import { alertCircleOutline } from 'ionicons/icons'
import { Device } from "../models";

/* enum IconColor {
    MEDIUM = "medium",
    SUCCESS = "success",
    ALARM = "alarm"
} */

const DeviceManageItem: React.FC<DeviceProps> = ({ device }) => {

    const { alarmStatus, name, id, address, description, owner } = device;

    // @ts-ignore
    /*     const ev = events.items as Event[] ? events.items as Event[] : []

        let statusIcon = cloudOfflineOutline
        let statusColor: IconColor;

        switch (status) {
            case 'OFFLINE':
                statusIcon = cloudOfflineOutline
                statusColor = IconColor.MEDIUM
                break;
            case 'ONLINE':
                statusIcon = cloudDoneOutline
                statusColor = IconColor.SUCCESS
                break;
            case 'ERROR':
                statusIcon = cloudOfflineOutline
                statusColor = IconColor.MEDIUM
                break;
            default:
                statusIcon = cloudOfflineOutline
                statusColor = IconColor.MEDIUM
                break;
        } */

    let alarmStatusIcon = alertCircleOutline
    let alarmStatusColor = 'light';

    switch (alarmStatus) {
        case 'ON':
            alarmStatusIcon = alertCircleOutline
            alarmStatusColor = 'danger'
            break;
        default:
            break;
    }

    return (
        <IonCard>
            <IonCardHeader style={{ paddingTop: '5px' }}>
                <IonCardTitle color="primary">
                    {name}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonText>
                            <h3>id: {id}</h3>
                            <h3>Omistaja: {owner}</h3>
                            <h3>Osoite: {address}</h3>
                            <h3>Kuvaus: {description}</h3>
                        </IonText>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {alarmStatus === "OFF" &&
                                <IonText>Ei hälytyksiä</IonText>
                            }
                            {alarmStatus === "ON" &&
                                <IonIcon icon={alarmStatusIcon} color={alarmStatusColor} size="large" slot="start" />
                            }
                        </IonCol>
                    </IonRow>
                    {/*                     <IonRow>
                        <IonCol size="12" class="ion-text-end ion-text-sm-start">
                            <IonButton shape="round" size="default" disabled={alarmStatus === 'OFF'} onClick={handleAlarmAck}>Kuittaa</IonButton>
                        </IonCol>
                    </IonRow>
                    {ev && ev?.map((event: Event) => <IonRow key={event?.id}>alarm: {event?.content}</IonRow>)} */}
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )

}

export default DeviceManageItem


interface DeviceProps {
    device: Device
}