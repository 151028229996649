import React from 'react'
// Amplify UI Primitives to style the custom components
import {
  Button,
  Text,
  View,
  useAuthenticator,
  useTheme,
  Heading
} from '@aws-amplify/ui-react'
import '../theme/variables.css';
import AisoftLogo from './AisoftLogo';

const AuthenticatorComponents = {

  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.xxl} style={{ display: 'flex' }}>
        <div style={{ width: '50%', margin: 'auto', display: "flex", flexDirection: "column" }}>
          <AisoftLogo textColor="white" />
          <Heading
            padding={`1rem 0 0 0`}
            color="#62A27F"
            level={3}
          >
            KAMU
          </Heading>
        </div>
      </View>
    );
  },

  SignIn: {
    /*     Header() {
          const { tokens } = useTheme();

          return (
            <Heading
              padding={`${tokens.space.medium} 0 0 ${tokens.space.medium}`}
              level={5}
            >
              Kirjaudu sisään
            </Heading>
          );
        }, */
    Footer() {
      const { toResetPassword } = useAuthenticator();

      const { tokens } = useTheme();

      return (
        <View textAlign="center">
          <Text color={`${tokens.colors.neutral['80']}`} fontSize="small"> Salasana unohtunut?<Button
            backgroundColor={tokens.colors.background.primary}
            fontWeight="normal"
            fontSize="small"
            onClick={toResetPassword}
            variation="link"
          >
            Nollaa salasana
          </Button></Text>
        </View>
      );
    },
  },

  SignUp: {
    /*     Header() {
          const { tokens } = useTheme();

          return (
            <Heading
              padding={`${tokens.space.medium} 0 0 ${tokens.space.medium}`}
              level={4}
            >
              Luo käyttäjätili
            </Heading>
          );
        }, */
    /*     Footer() {
          const { toSignIn } = useAuthenticator();

          return (
            <View textAlign="center">
              <Button
                fontWeight="normal"
                onClick={toSignIn}
                size="small"
                variation="link"
              >
                Takaisin kirjautumiseen
              </Button>
            </View>
          );
        }, */
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={`1rem ${tokens.space.xxl} 1rem ${tokens.space.xxl}`} style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
        <Text fontSize="small" color={`${tokens.colors.neutral['80']}`} style={{ marginTop: 'auto' }}>
          © 2022 <a href="https://aisoft.fi" target="_blank" rel="noreferrer">aisoft.fi</a>, Kaikki oikeudet pidätetään. | <a href="https://www.aisoft.fi/tietosuojaseloste" target="_blank" rel="noreferrer">Tietosuojaseloste </a>
        </Text>
      </View>
    );
  },
};

export default AuthenticatorComponents
