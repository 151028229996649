import { GraphQLResult } from "@aws-amplify/api";
import { ListDevicesQuery, Device, GetUserQuery, Event, User, GetDeviceQuery, UpdateUserMutation, UpdateDeviceMutation, OnUpdateByUserIdSubscription, OnUpdateUserSubscription, CreateUserDeviceMutation, UpdateEventMutation, GetUserAndDevicesAndEventsQuery, OnEventCreateOrUpdateSubscription, OnEventCreateOrUpdateByGroupSubscription, StartReservationHandlerStepFunctionMutation, LambdaResponse, AbortStepFunctionMutation, AbortStepFunctionResponse } from "../API";
import { SubscriptionValue } from "../models/graphql-api";
import { EventIncDevice } from "../types/kamuTypes";

// https://github.com/AppField/amplify-typescript-demo/blob/master/src/models/todo.ts

function mapListDevicesQuery(listDevicesQuery: GraphQLResult<ListDevicesQuery>): Device[] | [] {
    if (listDevicesQuery.data?.listDevices?.items) {
        return listDevicesQuery.data?.listDevices?.items?.map(e => e as Device)
    } else {
        return []
    }
}

function mapGetUserQuery(getUserQuery: GraphQLResult<GetUserQuery>): User {
    return { ...getUserQuery.data?.getUser } as User || {}
}


function mapGetDeviceQuery(getDeviceQuery: GraphQLResult<GetDeviceQuery>): Device {
    return { ...getDeviceQuery.data?.getDevice } as Device || {}
}

function mapUpdateUserMutation(updateUserMutation: GraphQLResult<UpdateUserMutation>): User {
    return { ...updateUserMutation.data?.updateUser } as User || {}
}

function mapUpdateDeviceMutation(updateDeviceMutation: GraphQLResult<UpdateDeviceMutation>): Device {
    return { ...updateDeviceMutation.data?.updateDevice } as Device || {}
}

function mapStartReservationHandlerStepFunction(startReservationHandlerStepFunction: GraphQLResult<StartReservationHandlerStepFunctionMutation>): LambdaResponse {
    return { ...startReservationHandlerStepFunction.data?.startReservationHandlerStepFunction} as LambdaResponse || {}
}

function mapAbortReservationStepFunction(abortReservationStepFunction: GraphQLResult<AbortStepFunctionMutation>): AbortStepFunctionResponse {
    return { ...abortReservationStepFunction.data?.abortStepFunction} as AbortStepFunctionResponse || {}
}

function mapUpdateByUserIdSubscription(updateUserSubscribtion: OnUpdateByUserIdSubscription): User {
    const newUser = updateUserSubscribtion.onUpdateByUserId
    return { ...newUser } as User || {}
}

function mapUpdateUserSubscription(updateUserSubscribtion: OnUpdateUserSubscription): User {
    const newUser = updateUserSubscribtion.onUpdateUser
    return { ...newUser } as User || {}
}

function mapCreateUserDeviceMutation(createUserDeviceMutation: GraphQLResult<CreateUserDeviceMutation>): User {
    return createUserDeviceMutation.data?.createUserDevice?.user as User || {}
}

// event mappings
function mapUpdateEventMutation(updateEventMutation: GraphQLResult<UpdateEventMutation>): Event {
    return { ...updateEventMutation.data?.updateEvent } as Event || {}
}

function mapGetUserAndDevicesAndEventsQueryQuery(getUserQuery: GraphQLResult<GetUserAndDevicesAndEventsQuery>): EventIncDevice[] | [] {

    const eventList = []

    if (getUserQuery.data?.getUser?.devices?.items) {

        for (let userDevice of getUserQuery.data?.getUser?.devices?.items) {
            for (let deviceEvent of userDevice?.device?.events?.items as EventIncDevice[]) {
                deviceEvent.location = userDevice?.device.address
                deviceEvent.deviceType = userDevice?.device.deviceType
                deviceEvent.name = userDevice?.device.name
                deviceEvent.description = userDevice?.device.description
                deviceEvent.owner = userDevice?.device.owner
                deviceEvent.deviceId = userDevice?.device.id
                eventList.push(deviceEvent)
            }
        }
    }

    return  eventList
}

function mapOnEventCreateOrUpdateSubscription(eventCreateOrUpdateSub: SubscriptionValue<OnEventCreateOrUpdateSubscription>): EventIncDevice | null {

    const subEventItem = eventCreateOrUpdateSub.value.data?.onEventCreateOrUpdate

    const deviceEvent = subEventItem as EventIncDevice

    deviceEvent.location = subEventItem?.device.address
    deviceEvent.deviceType = subEventItem?.device.deviceType
    deviceEvent.name = subEventItem?.device.name
    deviceEvent.description = subEventItem?.device.description
    deviceEvent.owner = subEventItem?.device.owner
    deviceEvent.deviceId = subEventItem?.device.id

    return deviceEvent
}



function mapOnEventCreateOrUpdateSubscription2(eventCreateOrUpdateSubscription: OnEventCreateOrUpdateSubscription): EventIncDevice | null {

    const subEventItem = eventCreateOrUpdateSubscription.onEventCreateOrUpdate

    const deviceEvent = subEventItem as EventIncDevice

    deviceEvent.location = subEventItem?.device.address
    deviceEvent.deviceType = subEventItem?.device.deviceType
    deviceEvent.name = subEventItem?.device.name
    deviceEvent.description = subEventItem?.device.description
    deviceEvent.owner = subEventItem?.device.owner
    deviceEvent.deviceId = subEventItem?.device.id

    return deviceEvent
  }

  function mapOnEventCreateOrUpdateByGroupSubscription(eventCreateOrUpdateByGroupSubscription: OnEventCreateOrUpdateByGroupSubscription): EventIncDevice | null {

    const subEventItem = eventCreateOrUpdateByGroupSubscription.onEventCreateOrUpdateByGroup

    const deviceEvent = subEventItem as EventIncDevice

    deviceEvent.location = subEventItem?.device.address
    deviceEvent.deviceType = subEventItem?.device.deviceType
    deviceEvent.name = subEventItem?.device.name
    deviceEvent.description = subEventItem?.device.description
    deviceEvent.owner = subEventItem?.device.owner
    deviceEvent.deviceId = subEventItem?.device.id

    return deviceEvent
  }

  function mapOnEventCreateOrUpdateSubscriptionResponse(eventCreateOrUpdateSubscription: Event): EventIncDevice {

    const deviceEvent = eventCreateOrUpdateSubscription as EventIncDevice

    deviceEvent.location = eventCreateOrUpdateSubscription?.device?.address
    deviceEvent.deviceType = eventCreateOrUpdateSubscription?.device?.deviceType
    deviceEvent.name = eventCreateOrUpdateSubscription?.device?.name
    deviceEvent.description = eventCreateOrUpdateSubscription?.device?.description
    deviceEvent.owner = eventCreateOrUpdateSubscription?.device?.owner
    deviceEvent.deviceId = eventCreateOrUpdateSubscription?.device?.id

    return deviceEvent
  }


export {
    mapListDevicesQuery,
    mapGetUserQuery,
    mapGetDeviceQuery,
    mapUpdateUserMutation,
    mapUpdateDeviceMutation,
    mapUpdateByUserIdSubscription,
    mapUpdateUserSubscription,
    mapCreateUserDeviceMutation,
    mapUpdateEventMutation,
    mapGetUserAndDevicesAndEventsQueryQuery,
    mapOnEventCreateOrUpdateSubscription,
    mapOnEventCreateOrUpdateSubscription2,
    mapOnEventCreateOrUpdateByGroupSubscription,
    mapOnEventCreateOrUpdateSubscriptionResponse,
    mapStartReservationHandlerStepFunction,
    mapAbortReservationStepFunction
}