import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

function makeComparator(key: any, order = 'asc') {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key))
      return 0;

    const aVal = (typeof a[key] === 'string')
      ? a[key].toUpperCase()
      : a[key];
    const bVal = (typeof b[key] === 'string')
      ? b[key].toUpperCase()
      : b[key];

    let comparison = 0;
    if (aVal > bVal)
      comparison = 1;
    if (aVal < bVal)
      comparison = -1;

    return order === 'desc'
      ? (comparison * -1)
      : comparison
  };
}


function hexToRGB(hex: string, alpha: string) {
  hex = hex.replace('#', '')

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

const checkCameraPermission = async () => {
  //const { BarcodeScanner, PhotoLibrary } = Plugins
  // check if user already granted permission
  const status = await BarcodeScanner.checkPermission({ force: false })

  console.log('checkCameraPermission status : ', JSON.stringify(status))

  if (status.granted) {
    // user granted permission
    return true
  }

  if (status.denied) {
    // user denied permission
    // the user denied permission for good
    // redirect user to app settings if they want to grant it anyway
    /*
    const c = confirm(
      'If you want to grant permission for using your camera, enable it in the app settings.',
    );

    if (c) {
      BarcodeScanner.openAppSettings();
    }*/
    return false
  }

  if (status.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (status.neverAsked) {
    // user has not been requested this permission before
    // it is advised to show the user some sort of prompt
    // this way you will not waste your only chance to ask for the permission
    alert(
      'We need your permission to use your camera to be able to scan barcodes'
    )
    /*
    const c = confirm(
      'We need your permission to use your camera to be able to scan barcodes',
    );
    if (!c) {
      return false;
    }*/
  }

  if (status.restricted || status.unknown) {
    // ios only
    // probably means the permission has been denied
    return false
  }

  // user has not denied permission
  // but the user also has not yet granted the permission
  // so request it
  const statusRequest = await BarcodeScanner.checkPermission({ force: true })

  if (statusRequest.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (statusRequest.granted) {
    // the user did grant the permission now
    return true
  }

  // user did not grant the permission, so he must have declined the request
  return false
}

export { makeComparator, hexToRGB, checkCameraPermission }