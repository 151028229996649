import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './state/store'
import * as serviceWorker from './serviceWorker';
import Amplify from "@aws-amplify/core";
// import { isPlatform } from '@ionic/react';
import awsconfig from './aws-exports';
// import { pushInit } from './utils/push';
// import globalHook, { Store } from 'use-global-hook';
Amplify.configure(awsconfig);

(globalThis as any).subscription = null;

(globalThis as any).eventSubscription = null;
(globalThis as any).groupEventSubscription = null;


//Amplify.configure({ config: { awsconfig } });

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// init push notifications

/* if(isPlatform('ios') || isPlatform('android')){
    const push = pushInit()
    push.on('registration', (data) => {
        console.log('FCM-avain: ', data)
    });

    try {
        push.on('notification', (data) => {
            alert('moikka')
            console.log(data)
            console.log(data.message)
            // data.title,
            // data.count,
            // data.sound,
            // data.image,
            // data.additionalData

            push.finish(function(){
                console.log("notification received successfully");
              })
        });
    } catch (error) {
        console.log('juu u', error)
    }



    push.on('error', data => {
        console.log(data)
    })
} */
