import { useState, useEffect } from 'react'
import { GlobalStateEnum, ActionsType, GlobalStateType } from './user-store'

// @ts-ignore
let globalState = {} as GlobalStateType
let listeners = [] as any[]
let actions = {} as ActionsType

export const useStore: UseStoreFunction = (shouldListen = true) => {

    const setState = useState(globalState)[1]

    const dispatch: DispatchFunction = (actionIdentifier, payload) => {
        const newState = actions[actionIdentifier](globalState, payload)
        globalState = { ...globalState, ...newState }

        for (const listener of listeners) {
            listener(globalState)
        }
    }

    useEffect(() => {
        if (shouldListen) {
            // when component renders that uses this hook, register to listen state
            listeners.push(setState)
        }

        return () => {
            if (shouldListen) {
                // clean up
                listeners = listeners.filter(li => li !== setState)
            }
        }

    }, [setState, shouldListen])

    return [globalState, dispatch]
}

export const initStore = (userActions: object, initialState: object) => {
    if (initialState) {
        // merge state if initial state given
        globalState = { ...globalState, ...initialState }
    }
    actions = { ...actions, ...userActions }
}

interface DispatchFunction {
    (com: keyof typeof GlobalStateEnum, payload: any): void;
}

interface UseStoreFunction {
    (shouldListen: boolean): [GlobalStateType, DispatchFunction];
}