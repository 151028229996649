/// <reference types="@types/google.maps" />

import React, { useState, useCallback, useEffect, CSSProperties, useRef } from 'react';
import './GoogleMapComp.css';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, DirectionsService } from '@react-google-maps/api';

import { Geolocation } from '@capacitor/geolocation';
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonSpinner, IonText, useIonAlert } from '@ionic/react';
import { analyticsOutline, carSportOutline } from 'ionicons/icons';
import styled from 'styled-components';


const containerStyle: CSSProperties = {
  width: '100%',
  height: '400px'
};

/* const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  paths: [
    { lat: 37.772, lng: -122.214 },
    { lat: 21.291, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 }
  ],
  zIndex: 1
}; */

const mapOptions: google.maps.MapOptions = {
  gestureHandling: 'none',
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  keyboardShortcuts: false,
  backgroundColor: '#000000'
}

export interface RouteInterface {
  destination: string | null | undefined
}


const GoogleMapComp: React.FC<RouteInterface> = ({ destination }) => {

  const apiKey = 'AIzaSyBEwUPy_dLOEHWauvZzsMVRUpI6nNaJJmU'

  const { isLoaded, loadError } = useJsApiLoader({
    language: 'fi',
    region: 'fi',
    googleMapsApiKey: apiKey
  })

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 61.2101833,
    lng: 26.0458743
  })

  const [myLocation, setMyLocation] = useState<google.maps.LatLngLiteral>()
  const [eventLocation, setEventLocation] = useState<google.maps.LatLngLiteral>()

  const [myAddress, setMyAddress] = useState('')

  const [routeDirections, setRouteDirections] = useState<google.maps.DirectionsResult>()


  const [travelMode] = useState<google.maps.TravelMode>('DRIVING' as google.maps.TravelMode.DRIVING)
  const [execute, setExecute] = useState(false)

  /*   const [origin, setOrigin] = useState<string | google.maps.LatLng | google.maps.LatLngLiteral>('heinola')
    const [destination, setDestination] = useState<string | google.maps.LatLng | google.maps.LatLngLiteral>('lahti') */

  const originRef = useRef<HTMLIonInputElement | null>()
  const destinationRef = useRef<HTMLIonInputElement | null>()

  const [present] = useIonAlert();

  const onLoad = useCallback((map) => {

    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)

  }, [])

  // const path: google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[] | google.maps.LatLngLiteral[] | undefined = [];

  useEffect(() => {

    const doMapThings = async () => {
      // https://developers.google.com/maps/documentation/javascript/reference?csw=1

      if (map && myLocation) {
        map.panTo(myLocation)

        // await sleep(2000)
        /*         path.push(myLocation as google.maps.LatLng)
                path.push(tokapiste) */

        map.setZoom(12)
      }
    }

    doMapThings()

  }, [myLocation, map])


  useEffect(() => {
    const getMyLocation = async () => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        const { longitude: lng, latitude: lat } = coordinates?.coords

        if (lng) {
          const center: google.maps.LatLngLiteral = {
            lng,
            lat
          }

          setMyLocation(center)
          setMapCenter(center)

          // get my address. Reverse geocoding
          const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${apiKey}`)
          const resJson = await res.json()

          if (resJson.results) {
            setMyAddress(resJson.results[0].formatted_address)
          }

          // get my address. Reverse geocoding
          const resEvent = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${destination}&key=${apiKey}`)
          const resEventJson = await resEvent.json()

          console.log('ressi',resEventJson)

          if (resJson.results) {
            setEventLocation(resEventJson.results[0].geometry.location)
          }

        }

      } catch (error) {
        console.log(error)
      }
    }

    getMyLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  const directionsCallback = (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
    // console.log(response)

    setExecute(false)
    console.log("Directions result ", result)

    if (status !== null) {
      if (status === 'OK' && result) {
        setRouteDirections(result!)
      } else if (status === google.maps.DirectionsStatus.NOT_FOUND) {
        present('Reittiä ei löytynyt', [{ text: 'Ok' }])
      } else {
        console.log('response: status & result  ', status, result)
      }
    }

  }

  const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const directionServiceOptions: google.maps.DirectionsRequest = {
    destination: destinationRef.current?.value as string,
    origin: originRef.current?.value as string,
    travelMode
  }

  const directionsRendererOptions: google.maps.DirectionsRendererOptions = {
    directions: routeDirections
  }

  const org = originRef.current?.value
  const dest = destinationRef.current?.value

  return <GridStyled>
    {loadError &&
      <IonRow>
        <IonCol>
          {loadError}
        </IonCol>
      </IonRow>
    }
    <IonRow>
      <IonCol size="12">
        {isLoaded ?
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={17}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={mapOptions}
          >
            { /* Child components, such as markers, info windows, etc. */}
            {myLocation &&
              <Marker
                position={myLocation as google.maps.LatLngLiteral}
                draggable={false}
                label="Sinä"
              />
            }
            <Marker
              position={eventLocation as google.maps.LatLngLiteral}
              draggable={false}
              icon={image}
              label="Hälytys"
            />
            {
              (
                dest !== '' &&
                org !== '' &&
                execute
              ) &&
              <DirectionsService
                options={directionServiceOptions}
                callback={(r, s) => directionsCallback(r, s)}
              />
            }
            {
              routeDirections &&
              <DirectionsRenderer
                options={directionsRendererOptions}
                // optional
                onLoad={directionsRenderer => {
                  console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                }}
              />
            }
          </GoogleMap>
          :
          <IonSpinner />
        }
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>

      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol sizeXs="12" sizeMd="6">
        <IonItem style={{ width: '100%' }}>
          <IonLabel>
            <IonText>
              <h3>Lähtöpiste:</h3>
            </IonText>
          </IonLabel>
          <IonInput ref={originRef as any} value={myAddress} placeholder="Kirjoita lähtöpiste..."></IonInput>
        </IonItem>
      </IonCol>
      <IonCol sizeXs="12" sizeMd="6">
        <IonItem style={{ width: '100%' }}>
          <IonLabel>
            <IonText>
              <h3>Määränpää:</h3>
            </IonText>
          </IonLabel>
          <IonInput ref={destinationRef as any} value={destination ? destination : ''} placeholder="Kirjoita määränpää..."></IonInput>
        </IonItem>
      </IonCol>
    </IonRow>
    <IonRow class="ion-justify-content-end">
      <IonCol size="auto">
        <IonButton disabled={execute} fill="outline" size="default" shape="round" onClick={() => setExecute(true)}>
          <IonLabel>Hae reittiohjeet<IonSpinner hidden={!execute} style={{ verticalAlign: 'middle' }} /></IonLabel></IonButton>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        {(routeDirections && routeDirections.routes[0]?.legs[0]?.duration && routeDirections.routes[0]?.legs[0]?.distance) &&
          <IonList>
            <IonListHeader>
              <IonLabel>Reittitiedot</IonLabel>
            </IonListHeader>
            <IonItem style={{ width: '100%' }}>
              <IonIcon slot="start" color="secondary" icon={carSportOutline} />
              <IonLabel>
                <h3>Ajoaika autolla</h3>
                <h4>{routeDirections.routes[0].legs[0].duration.text} (perillä klo {new Date(new Date().getTime() + routeDirections?.routes[0]?.legs[0]?.duration.value * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})</h4>
              </IonLabel>
            </IonItem>
            <IonItem style={{ width: '100%' }}>
              <IonIcon slot="start" size="large" color="tertiary" icon={analyticsOutline} />
              <IonLabel>
                <h3>Etäisyys</h3>
                <h4>{routeDirections.routes[0].legs[0].distance.text}</h4>
              </IonLabel>
            </IonItem>
          </IonList>
        }
      </IonCol>
    </IonRow>
  </GridStyled>
};

export default GoogleMapComp;


const GridStyled = styled(IonGrid)`
  max-width: 60rem;
  & ion-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


`
