import React, { useEffect, useRef } from 'react'
import { ActionSheetButton, CreateAnimation, IonActionSheet, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonRow, IonText, IonTextarea, IonTitle, IonToolbar, useIonActionSheet, useIonLoading } from '@ionic/react';
import { close, helpCircle, accessibility, checkmark, ellipsisHorizontal, chatbubbleEllipsesSharp, mapOutline, playOutline, arrowBack, megaphoneOutline, timerOutline } from 'ionicons/icons';
import { useState } from 'react';
import './EventItem.css'
import { AbortStepFunctionMutationVariables, eventClasses, eventStatus, StartReservationHandlerStepFunctionMutationVariables } from '../API';
import { Auth } from 'aws-amplify';
import fi from 'date-fns/locale/fi'
import formatRelative from 'date-fns/formatRelative';
import styled from 'styled-components';
import { EventIncDevice } from '../types/kamuTypes';
import GoogleMapComp from './GoogleMapComp';
import PoseAnimation from './PoseAnimation';

import fallingIcon from '../assets/img/falling.svg'
import { useAppDispatch, useResultToast } from '../hooks/hooks';
import { updateEvent, fetchEvent } from '../state/eventsSlice';
import { addMinutes, format } from 'date-fns';
import callGraphQL from '../models/graphql-api';
import { abortStepFunction, startReservationHandlerStepFunction } from '../graphql/mutations';
import { mapAbortReservationStepFunction, mapStartReservationHandlerStepFunction } from '../graphql/queryMapping';
import { useTranslation } from 'react-i18next';

const EventItem: React.FC<EventProps> = ({ event, currentUserId }) => {

    const {
        id,
        deviceId,
        name,
        location,
        status,
        content,
        deviceType,
        ackUser,
        ackTime,
        targetClass,
        reservedByUserId,
        reservedUntil,
        reservedByUser,
        predClass,
        createdAt,
        stepFunctionID,
        data, /* pose animation */
        freeForm = '' } = event

    const dispatch = useAppDispatch()

    const [showActionSheet, setShowActionSheet] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [showMapModal, setShowMapModal] = useState(false)
    const [showPoseAnimationModal, setShowPoseAnimationModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const { t } = useTranslation();

    const resultToast = useResultToast();

    const [showLoader, dismiss] = useIonLoading();

    const commentInputRef = useRef<HTMLIonTextareaElement>(null)

    const [presentReserveActionSheet, dismissReserveActionSheet] = useIonActionSheet();

    const [freeFormTemp, setFreeFormTemp] = useState<string | null | undefined>(freeForm)

    const alarmAckHandler = async (targetClass: eventClasses) => {

        setLoading(true)

        try {
            // get current user's cognito id
            const authUser = await Auth.currentAuthenticatedUser()

            const updateEventInput: Partial<EventIncDevice> = {
                id: id,
                ackUserId: authUser.attributes.sub,
                ackTime: new Date().toISOString(),
                status: eventStatus.ALARM_ACKNOWLEDGED,
                targetClass: targetClass,
                content: content
            }

            dispatch(updateEvent(updateEventInput))

            if (stepFunctionID) {
                const abortStepFunctionParam: AbortStepFunctionMutationVariables = {
                    stepFunctionArn: stepFunctionID
                }
                // abort step machine and get id as response
                const res = await callGraphQL(abortStepFunction, abortStepFunctionParam)
                const abortResponse = mapAbortReservationStepFunction(res as any)

                console.log(abortResponse)
            }

            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const freeFormSaveHandler = async () => {

        setLoading(true)

        try {
            const updateEventInput: Partial<EventIncDevice> = {
                id: id,
                freeForm: freeFormTemp
            }

            dispatch(updateEvent(updateEventInput))

            setLoading(false)
            setShowCommentModal(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const focusTextarea = async () => {
        if (commentInputRef.current) {
            const inputElem = await commentInputRef.current.getInputElement()
            const testi = inputElem.value.length
            inputElem.focus()
            inputElem.selectionStart = testi
            inputElem.selectionEnd = testi
        }
    }

    useEffect(() => {
        if (showCommentModal) {
            setFreeFormTemp(freeForm)
        }
    }, [freeForm, showCommentModal])

    const locale = {
        ...fi
    }

    const relativeEventTime = formatRelative(new Date(createdAt as string), new Date(), { locale })
    const relativeAckTime = formatRelative(new Date(ackTime as string), new Date(), { locale })

    const eventIcon = () => {
        switch (deviceType) {
            case "KAMU":
                return <IonItem className="kamu-event-item-header">
                    <IonAvatar slot="start">
                        <IonIcon size="large" icon={fallingIcon} color="secondary" />
                    </IonAvatar>
                    <IonLabel style={{
                        textTransform: 'uppercase',
                        fontWeight: 500
                    }}><h5>Kaatuminen havaittu</h5>
                    </IonLabel>
                </IonItem>
            case "VOKA":
                return <IonItem className="kamu-event-item-header">
                    <IonAvatar slot="start">
                        <IonIcon size="large" icon={megaphoneOutline} color="primary" />
                    </IonAvatar>
                    <IonLabel style={{
                        textTransform: 'uppercase',
                        fontWeight: 500
                    }}><h5>Äänikutsu havaittu</h5>
                    </IonLabel>
                </IonItem>
            default:
                return <IonItem className="kamu-event-item-header">
                    <IonAvatar slot="start">
                        <IonIcon size="large" icon={fallingIcon} color="secondary" />
                    </IonAvatar>
                    <IonLabel style={{
                        textTransform: 'uppercase',
                        fontWeight: 500
                    }}><h5>Kaatuminen havaittu</h5>
                    </IonLabel>
                </IonItem>
        }
    }


    const actionButtons: ActionSheetButton[] = [{
        text: predClass === "VOICE_CALL" ? "Kyllä" : 'Kyllä, kaaduttu',
        icon: checkmark,
        cssClass: "fall-icon-css",
        handler: () => {
            if (predClass) {
                alarmAckHandler(predClass)
            }
        }
    }]

    actionButtons.push({
        text: predClass === "VOICE_CALL" ? "Ei, väärä hälytys" : 'Ei, syy mysteeri',
        icon: helpCircle,
        role: "destructive",
        handler: () => {
            alarmAckHandler(eventClasses.FALSE_ALARM)
        }
    })

    if (predClass !== "VOICE_CALL") {
        actionButtons.push({
            text: 'Ei, jumpattu tms',
            icon: accessibility,
            role: "destructive",
            handler: () => {
                alarmAckHandler(eventClasses.SPORT_OR_SOMETHING)
            }
        })
    }

    actionButtons.push({
        text: 'Peruuta',
        icon: close,
        role: 'cancel'
    })


    const eventReservation = async (minutesToReserve: number) => {
        const reserveUntil = addMinutes(new Date(), minutesToReserve)

        // show loader
        showLoader('Varataan', 3000, 'dots')

        try {
            const stepFunctionParam: StartReservationHandlerStepFunctionMutationVariables = {
                deviceID: deviceId ? deviceId : 'mystery_device',
                eventID: id,
                releaseDT: reserveUntil.toISOString()
            }
            // start step machine and get id as response
            const res = await callGraphQL(startReservationHandlerStepFunction, stepFunctionParam)
            const stepMachine = mapStartReservationHandlerStepFunction(res as any)

            // get current user's cognito id
            const authUser = await Auth.currentAuthenticatedUser()
            await dispatch(updateEvent({ id: id, reservedByUserId: authUser.attributes.sub, reservedUntil: reserveUntil.toISOString(), stepFunctionID: stepMachine.body?.executionArn }))
            dismiss()
        } catch (error) {
            console.log(error)

            dismiss()
        }
    }

    const releaseReservation = async () => {

        // show loader
        showLoader('Poistetaan varaus', 3000, 'dots')

        if (stepFunctionID) {
            try {
                const abortStepFunctionParam: AbortStepFunctionMutationVariables = {
                    stepFunctionArn: stepFunctionID
                }
                // abort step machine and get id as response
                const res = await callGraphQL(abortStepFunction, abortStepFunctionParam)
                const abortResponse = mapAbortReservationStepFunction(res as any)

                console.log(abortResponse)

                // update event record
                await dispatch(updateEvent({ id: id, reservedByUserId: null, stepFunctionID: null }))

                dismiss()

            } catch (error) {
                console.log(error)
            }
        } else {
            resultToast('Virhe: Varaus-id puuttuu', 'danger')

            dismiss()
        }

    }

    return (
        <>
            <CreateAnimation
                play={true}
                duration={800}
                keyframes={[
                    { transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)', opacity: '0', offset: 0, boxShadow: '0 0 12px 2px grey' },
                    { transform: 'perspective(400px) rotate3d(1, 0, 0, -20deg)', offset: 0.2 },
                    { transform: 'perspective(400px) rotate3d(1, 0, 0, 10deg)', opacity: '1', offset: 0.3 },
                    { transform: 'perspective(400px) rotate3d(1, 0, 0, -5deg)', opacity: '1', offset: 0.4 },
                    { transform: 'perspective(400px)', opacity: '1', offset: 0.5 },
                ]}
            >
                <IonCard style={{ overflow: 'visible' }} >
                    <IonFab vertical="top" horizontal="end">
                        <IonFabButton class="fab-button-kamu" size="small">
                            <IonButton class="fab-action-button" style={{height: "100%"}}>
                                <IonIcon icon={ellipsisHorizontal} slot="icon-only"></IonIcon>
                            </IonButton>
                        </IonFabButton>
                        <IonFabList side="bottom">
                            <IonFabButton color="tertiary" onClick={() => setShowCommentModal(true)}>
                                <IonIcon size="medium" icon={chatbubbleEllipsesSharp} />
                            </IonFabButton>
                            {deviceType === 'KAMU' &&
                            <IonFabButton color="tertiary" onClick={ async () => {
                                    showLoader('Ladataan', 3000, 'dots')
                                    await dispatch(fetchEvent({ id: event.id }))
                                    setShowPoseAnimationModal(true)
                                    dismiss()
                                }}>
                                <IonIcon icon={playOutline} />
                            </IonFabButton>
                            }
                            <IonFabButton color="tertiary" onClick={() => setShowMapModal(true)}>
                                <IonIcon icon={mapOutline} />
                            </IonFabButton>
                        </IonFabList>
                    </IonFab>

                    <IonToolbar class="kamu-toolbar" style={{ padding: '5px', paddingRight: 0, paddingBottom: 0 }}>
                        {eventIcon()}
                    </IonToolbar>

                    <IonCardHeader style={{ paddingTop: '5px' }}>
                        <IonCardTitle color="primary">
                            {name}
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonText>
                            <h3>Osoite: {location}</h3>
                            <h3>Aika: {relativeEventTime}</h3>
                            <h3>Lisätiedot: {content}</h3>
                        </IonText>

                        {ackTime &&
                            <IonText color="tertiary">
                                <h3>Kuitannut: {ackUser?.username} {relativeAckTime}</h3>
                                <h3>Todellinen syy: {t(`${targetClass}`)}</h3>
                            </IonText>
                        }

                        {(!ackTime && reservedByUserId && reservedUntil) &&
                            <IonText color="tertiary">
                                {reservedByUserId === currentUserId ?
                                    <h3>Varattu sinulle klo {format(new Date(reservedUntil), 'H:mm')} asti</h3>
                                    :
                                    <h3>Varattu käyttäjälle {reservedByUser?.username} klo {format(new Date(reservedUntil), 'H:mm')} asti</h3>
                                }
                            </IonText>
                        }
                    </IonCardContent>

                    {freeForm &&
                        <IonCardContent style={{ paddingTop: 0, whiteSpace: 'pre-wrap' }}>
                            <IonText>
                                <h3>Kommentit:</h3>
                                <h4><i>{freeForm}</i></h4>
                            </IonText>
                        </IonCardContent>
                    }

                    {/* show ack button when if alarm active */}
                    {(status === "ALARM_ACTIVE" && (!reservedByUserId || currentUserId === reservedByUserId)) &&
                        <IonGrid className="ion-no-padding " style={{ padding: '5px' }}>
                            <IonRow>
                                <IonCol></IonCol>
                                <IonCol size="auto" className="ion-no-padding ion-align-self-end">
                                    {!reservedByUserId &&
                                        <IonButton
                                            style={{ marginRight: '5px' }}
                                            fill="outline"
                                            size="small"
                                            shape="round"
                                            onClick={(e) => presentReserveActionSheet({
                                                buttons: [
                                                    {
                                                        text: '1 minuutti',
                                                        icon: timerOutline,
                                                        handler: () => {
                                                            eventReservation(1)
                                                        }
                                                    },
                                                    {
                                                        text: '15 minuuttia',
                                                        icon: timerOutline,
                                                        handler: () => {
                                                            eventReservation(15)
                                                        }
                                                    },
                                                    {
                                                        text: '30 minuuttia',
                                                        icon: timerOutline,
                                                        handler: () => {
                                                            eventReservation(30)
                                                        }
                                                    },
                                                    {
                                                        text: '1 tunti',
                                                        icon: timerOutline,
                                                        handler: () => {
                                                            eventReservation(60)
                                                        }
                                                    },
                                                    {
                                                        text: '2 tuntia',
                                                        icon: timerOutline,
                                                        handler: () => {
                                                            eventReservation(120)
                                                        }
                                                    },
                                                    {
                                                        text: "Peruuta",
                                                        icon: close,
                                                        role: 'cancel',
                                                        handler: () => {
                                                            dismissReserveActionSheet()
                                                        }
                                                    }
                                                ],
                                                header: 'Valitse varausaika'
                                            })}>
                                            Varaa
                                        </IonButton>}
                                    {reservedByUserId &&
                                        <IonButton
                                            style={{ marginRight: '5px' }}
                                            fill="outline"
                                            size="small"
                                            shape="round"
                                            onClick={() => releaseReservation()}
                                        >
                                            Vapauta varaus
                                        </IonButton>
                                    }
                                    <IonButton
                                        fill="solid"
                                        size="small"
                                        shape="round"
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowActionSheet(true)
                                        }}>
                                        Kuittaa
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    }
                </IonCard>

            </CreateAnimation>

            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'Odota...'}
                duration={5000}
            />

            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                header="Oliko hälytys todellinen?"
                subHeader="Valitse jokin"
                cssClass='action-sheet-class'
                buttons={actionButtons}
            >
            </IonActionSheet>

            {showMapModal &&
                <IonModal
                    cssClass="route-modal"
                    isOpen={showMapModal}
                    onDidDismiss={() => setShowMapModal(false)}
                    animated
                    swipeToClose>
                    <IonHeader translucent>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => setShowMapModal(false)}>
                                    <IonIcon icon={arrowBack} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Tapahtuma kartalla</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <GoogleMapComp destination={location} />
                    </IonContent>
                </IonModal>
            }

            {showCommentModal &&
                <IonModal
                    onDidPresent={() => focusTextarea()}
                    onDidDismiss={() => setShowCommentModal(false)}
                    keyboardClose
                    isOpen={showCommentModal}
                    animated
                    backdropDismiss={false}
                    swipeToClose={true}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Kirjoita kommentti</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent class="ion-padding">
                        <IonTextareaStyled
                            ref={commentInputRef}
                            inputMode="text"
                            autofocus
                            enterkeyhint="enter"
                            debounce={300}
                            rows={20}
                            value={freeFormTemp}
                            onIonChange={e => setFreeFormTemp(e.detail.value!)}
                            placeholder='Kirjoita tarkemmat huomiot'>
                        </IonTextareaStyled>
                    </IonContent>

                    <IonFooter className="ion-no-border">
                        <IonToolbar className="footer-toolbar">
                            <IonButtons slot="end" >
                                <IonButton
                                    shape="round"
                                    fill="outline"
                                    size="default"
                                    onClick={() => setShowCommentModal(false)}>
                                    Peruuta
                                </IonButton>
                                <IonButton
                                    shape="round"
                                    fill="solid"
                                    color="primary"
                                    disabled={freeForm === freeFormTemp}
                                    onClick={() => freeFormSaveHandler()}>
                                    Tallenna
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonFooter>

                </IonModal>
            }

            {showPoseAnimationModal && data &&
                <IonModal
                    cssClass="pose-modal"
                    isOpen={showPoseAnimationModal}
                    onDidDismiss={() => setShowPoseAnimationModal(false)}
                    backdropDismiss={false}
                    animated
                    swipeToClose>
                    <IonHeader translucent>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => setShowPoseAnimationModal(false)}>
                                    <IonIcon icon={arrowBack} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Tapahtuman animaatio</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <PoseAnimation data={data} showing={showPoseAnimationModal} />
                    </IonContent>
                </IonModal>
            }

        </>
    )

}

export default EventItem

interface EventProps {
    event: EventIncDevice
    currentUserId: string | null | undefined
}

const IonTextareaStyled = styled(IonTextarea)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
