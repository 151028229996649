/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        status
        predClass
        targetClass
        ackTime
        ackUserId
        data
        content
        freeForm
        deviceID
        groupID
        reservedByUserId
        reservedUntil
        stepFunctionID
        geoLocation
        createdAt
        updatedAt
        ackUser {
          id
          username
          fcmId
          notificationsOn
          status
          admin
          groupIDs
          createdAt
          updatedAt
        }
        reservedByUser {
          id
          username
          fcmId
          notificationsOn
          status
          admin
          groupIDs
          createdAt
          updatedAt
        }
        device {
          id
          deviceType
          name
          description
          status
          alarmStatus
          owner
          ownerId
          pairingActive
          address
          groupID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByDeviceByDate = /* GraphQL */ `
  query EventByDeviceByDate(
    $deviceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByDeviceByDate(
      deviceID: $deviceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        status
        predClass
        targetClass
        ackTime
        ackUserId
        data
        content
        freeForm
        deviceID
        groupID
        reservedByUserId
        reservedUntil
        stepFunctionID
        geoLocation
        createdAt
        updatedAt
        ackUser {
          id
          username
          fcmId
          notificationsOn
          status
          admin
          groupIDs
          createdAt
          updatedAt
        }
        reservedByUser {
          id
          username
          fcmId
          notificationsOn
          status
          admin
          groupIDs
          createdAt
          updatedAt
        }
        device {
          id
          deviceType
          name
          description
          status
          alarmStatus
          owner
          ownerId
          pairingActive
          address
          groupID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
