export const listDevicesJoinEvents = /* GraphQL */ `
  query ListDevicesJoinEvents(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          items {
          id
          eventID
          status
          predClass
          targetClass
          freeForm
          data
          content
          deviceID
          createdAt
          updatedAt
          stepFunctionID
        }
        nextToken
        }
      }
      nextToken
    }
  }
`;


export const getUserAndDevices = /* GraphQL */ `
  query GetUserAndDevices($id: ID!) {
    getUser(id: $id) {
      id
      username
      fcmId
      notificationsOn
      status
      createdAt
      updatedAt
      devices {
        items {
            id
            device {
                id
                deviceType
                name
                owner
                address
                alarmStatus
                createdAt
                description
                ownerId
                status
                updatedAt
            }
      }
    }
    }
  }
`;


export const getUserAndDevicesAndEvents = /* GraphQL */ `
  query GetUserAndDevicesAndEvents($id: ID!) {
    getUser(id: $id) {
      id
      username
      fcmId
      notificationsOn
      status
      createdAt
      updatedAt
      devices {
        items {
            id
            device {
                id
                deviceType
                name
                owner
                address
                alarmStatus
                createdAt
                description
                ownerId
                status
                updatedAt
                events(sortDirection: DESC, limit: 20) {
                  items {
                    createdAt
                    content
                    id
                    eventID
                    predClass
                    freeForm
                    status
                    targetClass
                    ackTime
                    ackUser{
                      username
                    }
                    reservedByUser{
                      username
                    }
                    reservedByUserId
                    reservedUntil
                    stepFunctionID
                    geoLocation
                  }
                  nextToken
                }
            }
      }
    }
    }
  }
`;
