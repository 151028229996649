/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateByUserId = /* GraphQL */ `
  subscription OnUpdateByUserId($id: String!) {
    onUpdateByUserId(id: $id) {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onEventCreateOrUpdate = /* GraphQL */ `
  subscription OnEventCreateOrUpdate($deviceID: String) {
    onEventCreateOrUpdate(deviceID: $deviceID) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onEventCreateOrUpdateByGroup = /* GraphQL */ `
  subscription OnEventCreateOrUpdateByGroup($groupID: String) {
    onEventCreateOrUpdateByGroup(groupID: $groupID) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserDevice = /* GraphQL */ `
  subscription OnCreateUserDevice {
    onCreateUserDevice {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserDevice = /* GraphQL */ `
  subscription OnUpdateUserDevice {
    onUpdateUserDevice {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserDevice = /* GraphQL */ `
  subscription OnDeleteUserDevice {
    onDeleteUserDevice {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
