import React, { useEffect, useState } from 'react';
import './i18n'
import {
  IonApp,
  IonImg,
  isPlatform
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Authenticator, Flex, Grid, View, useTheme, Heading } from '@aws-amplify/ui-react';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth, Hub, Logger, I18n, API } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { UpdateUserMutationVariables, User } from './API';
import { amplifyFi } from './locale/amplify-trans';
import usePush from './hooks/usePush'
import configureStore from './store/user-store'
import { createUser } from './graphql/mutations';
import { useAppDispatch, useResultToast } from './hooks/hooks';
import { fetchUser, updateUser } from './state/userSlice';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import AuthenticatorComponents from './components/AuthenticatorComponents';
import './theme/variables.css';
import styled from 'styled-components';
import TabsRoot from './components/TabsRoot';

// amplify language to finnish
I18n.setLanguage('fi-FI')
I18n.putVocabulariesForLanguage("fi-FI", amplifyFi);

I18n.putVocabulariesForLanguage('en', {
  'Create Account': 'Register', // Tab header
  'Create a new account': 'New User', // Header text
  'Confirm Password': 'Confirm your password', // Confirm Password label
  Email: 'Enter your email',
  'Phone Number': 'Enter your phone number',
});

configureStore();

const App: React.FC = () => {

  // @ts-ignore: Unreachable code error
  const { getToken, getNotification } = usePush() // hasPermission,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authState, setAuthState] = useState<AuthState>();
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInterface | undefined>();

  const dispatch = useAppDispatch()

  const { tokens } = useTheme();
  const resultToast = useResultToast();

  // show toast on push notification
  useEffect(() => {
    const notif = getNotification();
    if (notif?.title) {
      resultToast(notif.title, "danger")
    }
  }, [getNotification, resultToast])

  // lock screen oritentation
  useEffect(() => {
    if (isPlatform("capacitor")) {
      try {
        window.screen.orientation.lock('portrait');
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  useEffect(() => {
    if (!authState) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        setCognitoUser(authData);
      });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setCognitoUser(authData as CognitoUserInterface);
      console.log('auth state changing');
    });
  }, [authState]);

  // log authentication changes
  useEffect(() => {
    const logger = new Logger('My-Logger');

    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          logger.info('user signed in');
          setCognitoUser(data.payload)
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'signOut':
          logger.info('user signed out');
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        case 'tokenRefresh':
          logger.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          logger.error('token refresh failed');
          break;
        case 'configured':
          logger.info('the Auth module is configured');
      }
    }

    Hub.listen('auth', listener);
  }, [])


  useEffect(() => {

    const updateUserEffect = async () => {

      let fcmTokenId = null;
      if (isPlatform("ios") || isPlatform("android")) {
        fcmTokenId = getToken()
      }

      try {
        // get current auth user id
        const authUser = await Auth.currentAuthenticatedUser()
        const { sub: currentAuthUserId } = authUser.attributes

        // try to get user from cloud database
        const resUser = await dispatch(fetchUser({ id: currentAuthUserId }))

        const currentUser = resUser.payload as User

        // user exists
        if (currentUser) {

          let fcmIdsSet = new Set<String>([])

          if (currentUser.fcmId) {
            for (let fcm of currentUser?.fcmId) {
              if (typeof (fcm) === 'string' && fcm.length > 1) {
                fcmIdsSet.add(fcm)
              }
            }
          }

          // check if fcmid is new
          if (typeof (fcmTokenId) === 'string' && fcmTokenId.length > 1 && !fcmIdsSet.has(fcmTokenId)) {

            fcmIdsSet.add(fcmTokenId);

            // @ts-ignore
            const fcmIdArray: string[] = [...fcmIdsSet]

            // update ids in the cloud
            const updateUserInput: UpdateUserMutationVariables = {
              input: {
                id: currentAuthUserId,
                fcmId: fcmIdArray
              }
            }

            // update user is new token added
            console.log("updateUserEffect User token updated successfully!");
            await dispatch(updateUser(updateUserInput))
          }

        } else {
          // new user, save to database
          const newUserData = {
            id: currentAuthUserId,
            username: authUser.username,
            fcmId: fcmTokenId ? [fcmTokenId] : [],
            status: "ONLINE",
            notificationsOn: true
          };

          console.log("User to cretate", newUserData)

          await API.graphql({ query: createUser, variables: { input: newUserData } })

          console.log("User created successfully!");
        }

      } catch (error) {
        console.log("Error on user update: ", JSON.stringify(error));
      }
    }

    if (cognitoUser) {
      // register user
      updateUserEffect()
    }

  }, [cognitoUser, getToken, dispatch])


  return (
    <Grid style={{ width: '100vw' }} templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <Flex
        backgroundColor={{ base: "transparent", medium: tokens.colors.background.primary }}
        justifyContent="center"
      >
        <Authenticator initialState="signIn" className="auth-root" loginMechanisms={['email']} components={AuthenticatorComponents}>
          {({ user }) => (
            <IonApp>
              <IonReactRouter>
                <TabsRoot user={user} />
              </IonReactRouter>
            </IonApp>
          )}
        </Authenticator>
      </Flex>
      <SignInGraphic
        height="100vh"
        display={{ base: "none", medium: "block" }}>
        <Heading
          display={cognitoUser && 'none'}
          padding={`${tokens.space.medium} 0 0 ${tokens.space.medium}`}
          color={tokens.colors.white}
          level={3}
        >
          Turvaa kotona asumiseen
        </Heading>
        <SignInImage src="/assets/img/grandma-animate.svg" />
      </SignInGraphic>
    </Grid>
  )

}

export default App;

const SignInImage = styled(IonImg)`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
`

const SignInGraphic = styled(View)`
    background-color: var(--ion-color-primary);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    place-content: center;
`;