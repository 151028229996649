/* Using with IonModal Component */

import React, { useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonInput, IonItem, IonItemDivider, IonList, IonButtons, IonLabel, IonListHeader, IonFab, IonFabButton, IonIcon, CreateAnimation, IonAlert, IonLoading, IonToast } from '@ionic/react';
import styled from 'styled-components';
import { add } from 'ionicons/icons';
import { DeviceType, KamuStatus } from '../models';
import { GetDeviceQuery, CreateDeviceInput, CreateDeviceMutation } from '../API';
import { createDevice } from '../graphql/mutations';
import { getDevice } from '../graphql/queries';
import { mapGetDeviceQuery } from '../graphql/queryMapping';
import callGraphQL from '../models/graphql-api';
// import { motion } from 'framer-motion';

const AddDeviceModal: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [toastContent, setToastContent] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')

  const [deviceId, setDeviceId] = useState<string>('');
  const [deviceType, setDeviceType] = useState<DeviceType | null | undefined>();
  const [deviceName, setDeviceName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [location, setLocation] = useState<string>();
  const [owner, setOwner] = useState<string>()


  const addDeviceHandler = async () => {

    setShowLoading(true)

    try {

      // check if the device already exist
      const resDevice = await callGraphQL<GetDeviceQuery>(getDevice, { id: deviceId });
      const device = mapGetDeviceQuery(resDevice)

      if (device.id) {
        // device already exist
        setErrorMsg(`Laite löytyy jo rekisteristä<br><br>id: ${deviceId}`);
        setShowAlert1(true)
        setShowLoading(false)

        return false
      }

      const newDevice: CreateDeviceInput = {
        id: deviceId,
        deviceType: deviceType,
        address: location,
        status: "OFFLINE" as KamuStatus,
        owner: owner,
        name: deviceName,
        description: phoneNumber
      }

      // add device to database
      await callGraphQL<CreateDeviceMutation>(createDevice, { input: newDevice });

      setShowLoading(false)
      setToastContent('Laite lisätty rekisteriin')
      setShowToast1(true)
      setShowModal(false)
      setDeviceId('')
      setDeviceType(null)
      setDeviceName('')
      setPhoneNumber('')
      setLocation('')
      setOwner('')

    } catch (error) {

      console.log(error)

    }

  };


  /*   const openScanner = async () => {
      const data = await BarcodeScanner.scan();

      try {

        const deviceObj = JSON.parse(data.text);

        if (deviceObj.type !== 'kamu') {
          throw Error('Type not Kamu')
        }

        if (!deviceObj.name) {
          throw Error('Name not defined')
        }

        // setQrCodeData(data.text);
        handleDeviceAdd(deviceObj.name);
      } catch (error) {
        setErrorMsg(error)
        setShowAlert1(true)
      }

    }; */

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      {showModal &&
        <IonModal isOpen={showModal} animated backdropDismiss={false} swipeToClose={true}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Lisää laite</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContentStyled >

            <IonListHeader lines="inset">
              <IonLabel>Syötä laitteen tiedot alla oleviin kenttiin</IonLabel>
            </IonListHeader>
            <IonList>

              <InputLabel>Laitteen id</InputLabel>
              <IonItemStyled>
                <IonInput value={deviceId} placeholder="Syötä laitteen id-numero..." onIonChange={e => setDeviceId(e.detail.value!)}></IonInput>
              </IonItemStyled>

              <InputLabel>Laitteen tyyppi</InputLabel>
              <IonItemStyled>
                <IonInput value={deviceType} placeholder="Syötä laitteen tyyppi..." onIonChange={e => setDeviceType(e.detail.value! as any)}></IonInput>
              </IonItemStyled>

              <InputLabel>Laitteen nimi</InputLabel>
              <IonItemStyled>
                <IonInput value={deviceName} placeholder="Syötä laitteen nimi..." onIonChange={e => setDeviceName(e.detail.value!)}></IonInput>
              </IonItemStyled>

              <InputLabel>Omistaja</InputLabel>
              <IonItemStyled>
                <IonInput value={owner} placeholder="Syötä omistaja..." onIonChange={e => setOwner(e.detail.value!)}></IonInput>
              </IonItemStyled>

              <InputLabel>Omistajan puhelinnumero</InputLabel>
              <IonItemStyled>
                <IonInput value={phoneNumber} placeholder="Syötä omistajan puhelinnumero..." onIonChange={e => setPhoneNumber(e.detail.value!)}></IonInput>
              </IonItemStyled>

              <InputLabel>Sijainti</InputLabel>
              <IonItemStyled>
                <IonInput required value={location} placeholder="Syötä osoite tai jokin muu selkeä sijainti..." onIonChange={e => setLocation(e.detail.value!)}></IonInput>
              </IonItemStyled>

            </IonList>
          </IonContentStyled>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton color="medium" onClick={() => setShowModal(false)}>Peruuta</IonButton>
              <IonButton disabled={deviceId?.length === 0} onClick={() => addDeviceHandler()}>Hyväksy</IonButton>
            </IonButtons>
          </IonToolbar>

        </IonModal>}
      <CreateAnimation
        duration={1500}
        iterations={Infinity}
        fromTo={[
          { property: 'transform', fromValue: 'translateX(0px)', toValue: 'translateX(100px)' },
          { property: 'opacity', fromValue: '1', toValue: '0.2' }
        ]}
      >
        <IonFabButton onClick={() => setShowModal(true)}>
          <IonIcon icon={add} />
        </IonFabButton>
      </CreateAnimation>
      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        /*         cssClass="my-custom-class" */
        header={"Virhe!"}
        message={errorMsg}
        buttons={["OK"]}
      />
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={toastContent}
        duration={1500}
      />
      <IonLoading
        /*         cssClass='my-custom-class' */
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Odota..."}
        duration={5000}
      />
    </IonFab>
  );
};

export default AddDeviceModal


const InputLabel = styled(IonItemDivider)`
  width: 100%;
`

const IonItemStyled = styled(IonItem)`
  width: 100%;
`

const IonContentStyled = styled(IonContent)`
  // --background: green;
  --padding-top: 1rem;
  --padding-end: 1rem;
  --padding-start: 1rem;
`
