
/* import { isPlatform } from '@ionic/react'
import { motion } from 'framer-motion' */
import React from 'react'
// import { hexToRGB } from '../utils/utils'

interface AisoftLogoInterface {
    textColor?: string
    backgroundColor?: string
}

const AisoftLogo: React.FC<AisoftLogoInterface> = ({ textColor, backgroundColor }) => {

    // const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')

    const bgColor = backgroundColor ? backgroundColor : getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');

    const txtColor = textColor ? textColor : "white";

   /*  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const logoTextVariants = {
        hidden: {
            pathLength: 0,
            fill: "rgba(255, 255, 255, 0)"
        },
        visible: {
            pathLength: 1,
            fill: "rgba(255, 255, 255, 1)"
        }
    }

    const rectBgVariants = {
        hidden: {
            pathLength: 0,
            fill: "rgba(255, 255, 255, 0)",
        },
        visible: {
            pathLength: 1,
            fill: hexToRGB(primaryColor, '1'),
        }
    } */

  /*   if (!isSafari && !isPlatform('ios')) {
        return (
            <motion.svg
                viewBox="76.156 106.736 43.627 45.508"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.rect
                    x="76.156"
                    y="106.736"
                    width="43.627"
                    height="45.508"
                    variants={rectBgVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        default: { duration: 2, ease: "easeInOut" },
                        fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                    }}
                />
                <motion.path
                    d="M 104.863 120.006 L 104.863 141.006 L 100.683 141.006 L 100.683 135.336 L 85.953 135.336 L 85.953 141.006 L 81.763 141.006 L 81.763 129.456 C 81.763 127.769 82.053 126.216 82.633 124.796 C 83.213 123.376 84.02 122.156 85.053 121.136 C 86.08 120.116 87.296 119.323 88.703 118.756 C 90.11 118.183 91.646 117.896 93.313 117.896 L 102.753 117.896 C 103.046 117.896 103.323 117.949 103.583 118.056 C 103.836 118.169 104.06 118.323 104.253 118.516 C 104.446 118.709 104.596 118.933 104.703 119.186 C 104.81 119.446 104.863 119.719 104.863 120.006 Z M 85.953 129.456 L 85.953 131.146 L 100.683 131.146 L 100.683 122.086 L 93.313 122.086 C 93.186 122.086 92.916 122.106 92.503 122.146 C 92.09 122.186 91.61 122.279 91.063 122.426 C 90.523 122.579 89.953 122.809 89.353 123.116 C 88.753 123.429 88.2 123.859 87.693 124.406 C 87.186 124.959 86.77 125.646 86.443 126.466 C 86.116 127.286 85.953 128.283 85.953 129.456 ZM 113.947 117.896 L 113.947 141.006 L 109.767 141.006 L 109.767 117.896 L 113.947 117.896 Z"
                    initial="hidden"
                    variants={logoTextVariants}
                    animate="visible"
                    stroke="white"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    strokeWidth={0.4}
                    transition={{
                        default: { duration: 2, ease: "easeInOut" },
                        fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                    }} />
            </motion.svg>
        )

    } else { */
        return (
            <svg width="100%" height="100%" viewBox="76.156 106.736 43.627 45.508" xmlns="http://www.w3.org/2000/svg">
                <rect x="76.156" y="106.736" width="43.627" height="45.508" style={{ fill: bgColor }} ry="0px" />
                <path style={{ fill: txtColor }} d="M 104.863 120.006 L 104.863 141.006 L 100.683 141.006 L 100.683 135.336 L 85.953 135.336 L 85.953 141.006 L 81.763 141.006 L 81.763 129.456 C 81.763 127.769 82.053 126.216 82.633 124.796 C 83.213 123.376 84.02 122.156 85.053 121.136 C 86.08 120.116 87.296 119.323 88.703 118.756 C 90.11 118.183 91.646 117.896 93.313 117.896 L 102.753 117.896 C 103.046 117.896 103.323 117.949 103.583 118.056 C 103.836 118.169 104.06 118.323 104.253 118.516 C 104.446 118.709 104.596 118.933 104.703 119.186 C 104.81 119.446 104.863 119.719 104.863 120.006 Z M 85.953 129.456 L 85.953 131.146 L 100.683 131.146 L 100.683 122.086 L 93.313 122.086 C 93.186 122.086 92.916 122.106 92.503 122.146 C 92.09 122.186 91.61 122.279 91.063 122.426 C 90.523 122.579 89.953 122.809 89.353 123.116 C 88.753 123.429 88.2 123.859 87.693 124.406 C 87.186 124.959 86.77 125.646 86.443 126.466 C 86.116 127.286 85.953 128.283 85.953 129.456 ZM 113.947 117.896 L 113.947 141.006 L 109.767 141.006 L 109.767 117.896 L 113.947 117.896 Z" />
            </svg>
        )
    //}

}

export default AisoftLogo
