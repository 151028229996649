import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetEventQuery,
  GetEventQueryVariables,
  CreateEventMutation,
  DeleteEventMutation,
  UpdateEventMutation,
  Event,
  CreateEventMutationVariables,
  DeleteEventMutationVariables,
  GetUserAndDevicesAndEventsQueryVariables,
  GetUserAndDevicesAndEventsQuery,
} from "../API";
import { RootState } from "./store";
import {
  createEvent,
  deleteEvent,
  updateEvent as updateEventMut,
} from "../graphql/mutations";
import callGraphQL from "../models/graphql-api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getUserAndDevicesAndEvents } from "../graphql/queriesCustom";
import { getEvent } from "../graphql/queries";
import { EventIncDevice } from "../types/kamuTypes";

// Define a type for the slice state
interface EventsState {
  Events: EventIncDevice[];
  status: "idle" | "pending" | "fulfilled" | "failed";
  error: string | null | undefined | [graphqlError];
}

// Define the initial state using that type
const initialState: EventsState = {
  Events: [],
  status: "idle",
  error: null,
};

function listEventsQuery(
  getUserAndDevicesAndEventsQuery: GraphQLResult<GetUserAndDevicesAndEventsQuery>
): EventIncDevice[] | [] {
  let events: EventIncDevice[] = [];
  if (getUserAndDevicesAndEventsQuery.data?.getUser?.devices?.items) {
    for (let device of getUserAndDevicesAndEventsQuery.data?.getUser?.devices
      ?.items) {
      if (device?.device.events?.items) {
        for (let ev of device?.device.events?.items) {
          if (ev) {
            const newEvent: EventIncDevice = {
              ...ev,
              location: device.device.address,
              deviceType: device.device.deviceType,
              name: device.device.name,
              description: device.device.description,
              owner: device.device.owner,
              deviceId: device.device.id,
            };
            events.push(newEvent);
          }
        }
      }
    }
  }
  return events;
}

// fetch events
export const fetchEvents = createAsyncThunk<
  EventIncDevice[],
  GetUserAndDevicesAndEventsQueryVariables,
  { rejectValue: GeneralGraphqlError }
>(
  "Events/fetchEvents",
  async (listUserDeviceEventsQueryInput, { rejectWithValue }) => {
    try {
      const resEvents = await callGraphQL<GetUserAndDevicesAndEventsQuery>(
        getUserAndDevicesAndEvents,
        listUserDeviceEventsQueryInput
      );
      const Events = listEventsQuery(resEvents);
      return Events;
    } catch (error) {
      const gqlError = error as GeneralGraphqlError;
      return rejectWithValue(gqlError);
    }
  }
);

function getEventQuery(
  getEventQuery: GraphQLResult<GetEventQuery>
): Event | null {
  if (getEventQuery.data?.getEvent) {
    return getEventQuery.data.getEvent as Event;
  } else {
    return null;
  }
}

// fetch more data for single event
export const fetchEvent = createAsyncThunk<
  Event | null,
  GetEventQueryVariables,
  { rejectValue: GeneralGraphqlError }
>("user/getEvent", async (getEventInput, { rejectWithValue }) => {
  try {
    const resEvent = await callGraphQL<GetEventQuery>(getEvent, getEventInput);
    const event = getEventQuery(resEvent);
    return event;
  } catch (error) {
    const gqlError = error as GeneralGraphqlError;
    return rejectWithValue(gqlError);
  }
});

function createEventMutation(
  createEventMutation: GraphQLResult<CreateEventMutation>
): Event {
  return ({ ...createEventMutation.data?.createEvent } as Event) || {};
}

export const addEvent = createAsyncThunk<
  Event,
  CreateEventMutationVariables,
  { rejectValue: GeneralGraphqlError }
>("Events/addEvent", async (createEventMutationInput, { rejectWithValue }) => {
  try {
    const resEvent = await callGraphQL<CreateEventMutation>(
      createEvent,
      createEventMutationInput
    );
    return createEventMutation(resEvent);
  } catch (error) {
    const gqlError = error as GeneralGraphqlError;
    return rejectWithValue(gqlError);
  }
});

type EventOperationError = {
  message: string;
};

function deleteEventMutation(
  deleteEventMutation: GraphQLResult<DeleteEventMutation>
): Event {
  return ({ ...deleteEventMutation.data?.deleteEvent } as Event) || {};
}

export const removeEvent = createAsyncThunk<
  Event,
  DeleteEventMutationVariables,
  { rejectValue: EventOperationError }
>("Events/removeEvent", async (deleteEventInput, { rejectWithValue }) => {
  const resEvent = await callGraphQL<DeleteEventMutation>(
    deleteEvent,
    deleteEventInput
  );
  const Event = deleteEventMutation(resEvent);
  if (Event.id) {
    return Event;
  } else {
    return rejectWithValue({
      message: "Failed to delete Event.",
    });
  }
});

export type GeneralGraphqlError = {
  data: {} | null;
  errors: [graphqlError] | null;
};

type graphqlError = {
  message: string;
  errorType: string;
};

function updateEventMutation(
  updateEventMutation: GraphQLResult<UpdateEventMutation>
): EventIncDevice {
  const rawEvent = updateEventMutation.data?.updateEvent;

  if (rawEvent?.id) {
    // @ts-ignore
    const eventIncDevice: EventIncDevice = {
      ...rawEvent,
      location: rawEvent?.device?.address,
      deviceType: rawEvent?.device?.deviceType,
      name: rawEvent?.device?.name,
      description: rawEvent?.device?.description,
      owner: rawEvent?.device?.owner,
      deviceId: rawEvent?.device?.id,
    };

    return eventIncDevice;
  } else {
    return (
      ({ ...updateEventMutation.data?.updateEvent } as EventIncDevice) || {}
    );
  }
}

export const updateEvent = createAsyncThunk<
  EventIncDevice,
  Partial<EventIncDevice>,
  { rejectValue: GeneralGraphqlError }
>("Events/updateEvent", async (updateEventInput, { rejectWithValue }) => {
  try {
    const resEvent = await callGraphQL<UpdateEventMutation>(updateEventMut, {
      input: { ...updateEventInput },
    });
    return updateEventMutation(resEvent);
  } catch (error) {
    const gqlError = error as GeneralGraphqlError;
    return rejectWithValue(gqlError);
  }
});

export const EventsSlice = createSlice({
  name: "Events",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    /*         addEvent: (state, action: PayloadAction<Event>) => {
                    // Redux Toolkit allows us to write "mutating" logic in reducers. It
                    // doesn't actually mutate the state because it uses the Immer library,
                    // which detects changes to a "draft state" and produces a brand new
                    // immutable state based off those changes

                    // const res = await API.graphql(graphqlOperation(createEvent, { input: stripped as createEventInput}))

                    console.log('Event added')
                    //state.Events += 1
                }, */
    addEventToOnlyState: (state, action: PayloadAction<EventIncDevice>) => {
      // add in the beginning
      state.Events = [action.payload, ...state.Events];
    },
    updateEventInStateOnly: (state, action: PayloadAction<EventIncDevice>) => {
      const idx = state.Events.findIndex(
        (event) => event.id === action.payload.id
      );
      console.log("subi", action.payload);
      state.Events[idx] = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    /*         fetchEvents: (state, action: PayloadAction<number>) => {
            console.log('Events fetched')
            // state.Events += action.payload
        } */
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        // fetch all devices
        state.status = "fulfilled";
        // console.log("vastaus", action.payload);
        // sort by date
        state.Events = action.payload.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      })
      .addCase(fetchEvents.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        // fetch more details for one event
        state.status = "fulfilled";
        // console.log("vastaus ", action.payload);
        const idx = state.Events.findIndex(
          (event) => event.id === action.payload?.id
        );
        if (idx !== -1 && action.payload) {
          state.Events[idx] = {...state.Events[idx], data:  action.payload.data } as EventIncDevice;
        }
      })
      .addCase(fetchEvent.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        // Add Event to the state array
        state.status = "fulfilled";
        // do not update state. Events will come by subscription
        // state.Events = [...state.Events, action.payload as EventIncDevice]
      })
      .addCase(addEvent.rejected, (state, action) => {
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.error = action.payload.errors;
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(removeEvent.fulfilled, (state, action) => {
        // filter removed Event from state
        state.Events = state.Events.filter(
          (Event) => Event.id !== action.payload.id
        );
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        // update fulfilled Event
        const idx = state.Events.findIndex(
          (Event) => Event.id === action.payload.id
        );
        console.log("huono", action.payload);
        state.Events[idx] = action.payload as EventIncDevice;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        console.log("virhe", action);
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.error = action.payload.errors;
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const { addEventToOnlyState, updateEventInStateOnly } =
  EventsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEvents = (state: RootState) => state.events.Events;

export default EventsSlice.reducer;
