export const updateUser2 = /* GraphQL */ `
  mutation UpdateUser2(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      fcmId
      notificationsOn
      status
      createdAt
      updatedAt
      devices {
        items {
            id
            device {
                id
                deviceType
                name
                owner
                address
                alarmStatus
                createdAt
                description
                ownerId
                status
                updatedAt
            }
        }
        nextToken
      }
    }
  }
`;


export const createUserDevice2 = /* GraphQL */ `
  mutation CreateUserDevice2(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        createdAt
        updatedAt
        devices {
            items {
            id
            device {
                id
                name
                owner
                address
                alarmStatus
                createdAt
                description
                ownerId
                status
                updatedAt
            }
        }
        nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;