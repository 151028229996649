import { User, Device } from '../models/index'
import { EventFilter, EventIncDevice } from '../types/kamuTypes';
import { initStore } from './store';

const configureStore = () => {
    const actions = {
        SET_CURRENT_USER: (curState: User | {}, newUser: User) => {
            return { currentUser: newUser }
        },
        SET_EVENTS: (curState: EventIncDevice[] | [], newEvents: EventIncDevice[]) => {
            return { events: newEvents }
        },
        SET_DEVICES: (curState: Device[] | [], newDevices: Device[]) => {
            return { devices: newDevices }
        },
        SET_EVENT_FILTER: (curState: EventFilter, newEventFilter: EventFilter) => {
            return { eventFilter: newEventFilter }
        },
    };
    initStore(actions, {
        currentUser: {} as User,
        events: [] as EventIncDevice[],
        devices : [] as Device[],
        eventFilter: {showOnlyActive: false, searchText: ''} as EventFilter
    });
};

export default configureStore;

export enum GlobalStateEnum {
    SET_CURRENT_USER,
    SET_EVENTS,
    SET_DEVICES,
    SET_EVENT_FILTER
}

export type ActionsType = {
    SET_CURRENT_USER: SetStateFunction
    SET_EVENTS: SetStateFunction
    SET_DEVICES: SetStateFunction
    SET_EVENT_FILTER: SetStateFunction
}

interface SetStateFunction {
    (curState: any, newState: any): object
}

export type GlobalStateType = {
    currentUser: User,
    events: EventIncDevice[],
    devices: Device[],
    eventFilter: EventFilter
}