export const onUpdateByUserId2 = /* GraphQL */ `
  subscription OnUpdateByUserId2($id: String!) {
    onUpdateByUserId(id: $id) {
      id
      username
      fcmId
      notificationsOn
      status
      createdAt
      updatedAt
      devices {
        items {
            id
            device {
                id
                deviceType
                name
                owner
                address
                alarmStatus
                createdAt
                description
                ownerId
                status
                updatedAt
            }
        }
        nextToken
      }
    }
  }
`;