import React, { useEffect, useRef, useState } from 'react';
import { IonAlert, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonItem, IonLabel, IonList, IonLoading, IonRow, IonToggle, useIonViewDidEnter, useIonViewWillLeave, isPlatform, IonFab, IonFabButton, IonIcon, IonText, IonCardContent, useIonAlert, useIonLoading } from '@ionic/react';
import './Settings.css';
import { Auth } from 'aws-amplify';
import { add } from 'ionicons/icons'
import { Device, User } from '../models';
import { GetUserQuery, OnUpdateByUserIdSubscription, UpdateUserMutation, DeleteUserDeviceMutation, UpdateUserInput } from '../API';
import { CreateUserDeviceMutation, GetDeviceQuery } from '../API'
import callGraphQL, { subscribeGraphQL2 } from '../models/graphql-api';
import { deleteUserDevice } from '../graphql/mutations';
import { updateUser2 } from '../graphql/mutationsCustom'
import { useStore } from '../store/store';
import { getUserAndDevices } from '../graphql/queriesCustom';
import { getDevice } from '../graphql/queries'
import { mapGetDeviceQuery, mapGetUserQuery, mapUpdateUserMutation } from '../graphql/queryMapping'
import { createUserDevice } from '../graphql/mutations'
import { makeComparator, checkCameraPermission } from '../utils/utils';
import { onUpdateByUserId2 } from '../graphql/subscriptionsCustom';
import styled from 'styled-components';
import CaptureQRPage from './CaptureQRPage'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import AddDeviceToUserModal from '../modals/AddDeviceToUserModal'
import { PageNames, PageWrapper } from '../components/PageWrapper';
import { useAuthenticator } from '@aws-amplify/ui-react';

// let subscription: any;
declare module globalThis {
  let subscription: any;
}

const Settings: React.FC = () => {

  const [showLoader, dismiss] = useIonLoading();

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [state, dispatch] = useStore(true)

  const [showLoading, setShowLoading] = useState(false)

  const { currentUser } = state

  const [userHelpState, setUserHelpState] = useState<User | undefined>()

  const [presentDeleteConfirmation] = useIonAlert();

  const [showAlert, setShowAlert] = useState<boolean>(false)

  const [errorMsg, setErrorMsg] = useState<string>('')

  const currentUserRef = useRef<User | undefined>(userHelpState);

  const [showAddDeviceToUserModal, setShowAddDeviceToUserModal] = useState(false)

  const [, setShowToast] = useState(false)
  const [, setToastContent] = useState('')

  useIonViewDidEnter(async () => {

    window.onbeforeunload = () => {
      if (globalThis.subscription && globalThis.subscription._state !== "closed") {
        console.log('unsubscribing...')
        globalThis.subscription.unsubscribe();
        console.log('done', globalThis.subscription)
      }

      // alert('moi');
      return "Good bye";
    }

    // console.log(globalThis)

    if (!user)
      showLoader('Ladataan', 3000, 'dots')

    try {
      // get current user's cognito id
      const authUser = await Auth.currentAuthenticatedUser()

      // get current user's userdata
      const resUser = await callGraphQL<GetUserQuery>(getUserAndDevices, { id: authUser.attributes.sub });
      const user = mapGetUserQuery(resUser)
      dispatch("SET_CURRENT_USER", user)

      setUserHelpState(user as User)

      // @ts-ignore
      // currentUserRef.current = { ...user }

      // subscribe for user changes if not subscribed
      if ((!subscription || subscription._state === "closed")) {
        // subscribe for changes
        console.log('subscribing...')

        // @ts-ignore
        /*         globalThis.subscription  =  API.graphql(graphqlOperation(onUpdateUser, { id: authUser.attributes.sub })).subscribe({
                  next: (response: any) => console.log(response)
              }); */

        // @ts-ignore
        /*       globalThis.subscription  =  API.graphql({query: onUpdateByUserId, variables: {id: authUser.attributes.sub }}).subscribe({
                next: (response: any) => console.log(response)
            }); */

        globalThis.subscription = await subscribeGraphQL2<OnUpdateByUserIdSubscription>(
          onUpdateByUserId2,
          { id: authUser.attributes.sub }
          ,
          (updateUserSubscription: OnUpdateByUserIdSubscription) => {
            const user = mapUpdateByUserIdSubscription(updateUserSubscription);
            // console.log('server pushing user: ', user)
            // @ts-ignore
            // console.log('current user', currentUserRef.current)

            // compare updated timestamp to render or not
            // @ts-ignore
            if (user?.updatedAt !== currentUserRef?.current?.updatedAt) {
              console.log('update from server...')
              dispatch("SET_CURRENT_USER", user)
            } else {
              console.info('no need to update')
            }

          })
        //console.log('done', globalThis.subscription)
      }

    } catch (error) {
      console.log(error)
    }

    dismiss()

  })

  /*   useEffect(() => {

      const doSub = async () => {
        // subscribe for changes
        console.log('subscribing new...')

        subscription = await subscribeGraphQL2<OnUpdateUserSubscription>(
          onUpdateUser,
          // @ts-ignore
          { id: authUserState?.attributes?.sub },
          (updateUserSubscription: OnUpdateUserSubscription) => {
            const user = mapUpdateUserSubscription(updateUserSubscription);
            console.log('server pushing user: ', user)
            // @ts-ignore
            console.log('current user', currentUserRef.current)

            // compare updated timestamp to render or not
            // @ts-ignore
            if (user?.updatedAt !== currentUserRef?.current?.updatedAt) {
              console.log('update from server...')
              dispatch("SET_CURRENT_USER", user)
            } else {
              console.info('no need to update')
            }
          })
        console.log('done', subscription)
      }

      // subscribe
      if ((!subscription || subscription._state !== "ready") && authUserState && currentUser.id && currentUserRef.current?.id) {
        doSub()
      }
    },[authUserState, currentUser.id, currentUserRef, dispatch]) */


  useEffect(() => {
    // console.log('current user', state.currentUser)
    // console.log('current user ref', currentUserRef.current)

    setErrorMsg('')

    if (userHelpState?.id) {
      //console.log('setting user ref...')
      // @ts-ignore
      currentUserRef.current = userHelpState
      //console.log('new current user', currentUserRef.current)
    }

  }, [userHelpState])

  useIonViewWillLeave(async () => {
    try {
      // unsubscribe
      if (globalThis.subscription && globalThis.subscription._state !== "closed") {
        console.log('unsubscribing...')
        await globalThis.subscription.unsubscribe();
        console.log('done', globalThis.subscription)
        globalThis.subscription = null;
      }

      window.onbeforeunload = null;
    } catch (error) {

    }
  })


  /*   const onUpdateUserHandler = (
      updateUserSubscription: OnUpdateUserSubscription
    ) => {
      const user = mapUpdateUserSubscription(updateUserSubscription);
      // @ts-ignore
      console.log('server pushing user: ', user)
      // @ts-ignore
      console.log('current user', currentUserRef.current)

      // compare updated timestamp to render or not
      // @ts-ignore
      if (user?.updatedAt !== currentUserRef?.current?.updatedAt) {
        console.log('update from server...')
        dispatch("SET_CURRENT_USER", user)
      } else {
        console.info('no need to update')
      }
    }; */

  /*   const onUpdateUserHandler2 = (
      updateUserSubscription: OnUpdateUserSubscription
    ) => {
      // @ts-ignore
      const user = mapUpdateUserSubscription(updateUserSubscription.value.data);
      console.log('server pushin user: ', user)
      // @ts-ignore
      if (!Object.is(user, currentUserRef.current)) {
        console.log('updating user')
        dispatch("SET_CURRENT_USER", user)
      }
    }; */


  const handleDeviceAdd = async (deviceId: string) => {
    setShowLoading(true)

    try {
      // get current user's cognito id
      const authUser = await Auth.currentAuthenticatedUser()

      console.log('handleDeviceAdd got authUser ', JSON.stringify(authUser))

      console.log(
        'handleDeviceAdd authUser.attributes ',
        JSON.stringify(authUser.attributes)
      )

      // get current user's userdata
      const resUser = await callGraphQL<GetUserQuery>(getUserAndDevices, { id: authUser.attributes.sub });
      console.log('handleDeviceAdd got resUser ', resUser)

      const user = mapGetUserQuery(resUser)
      console.log('handleDeviceAdd got user ', user)
      // check if the device exist and not already registered
      const resDevice = await callGraphQL<GetDeviceQuery>(getDevice, {
        id: deviceId
      })
      const device = mapGetDeviceQuery(resDevice)

      console.log('handleDeviceAdd got device ', device)
      if (!device.id) {
        // device doesn't exist
        setErrorMsg(`Laitetta ei löydy rekisteristä<br><br>id: ${deviceId}`)
        setShowAlert(true)
        setShowLoading(false)
        return false
      }

      // check if device already registered
      if (user.devices?.items) {
        for (let regDev of user?.devices?.items) {
          if (regDev?.device?.id === deviceId) {
            setErrorMsg(
              `Laite on jo sinulla seurannassa<br><br>id: ${deviceId}`
            )
            setShowAlert(true)
            setShowLoading(false)

            return false
          }
        }
      }

      // register device for user
      await callGraphQL<CreateUserDeviceMutation>(createUserDevice, {
        input: { userId: user.id, deviceId: deviceId }
      })

      setShowLoading(false)
      setToastContent('Laite rekisteröity')
      console.log('Laite rekisteröity')
      setShowToast(true)
    } catch (error) {
      console.log('Error adding device : ', JSON.stringify(error))
      let msg = ""
      if (error instanceof Error) {
        const err = error as unknown as GeneralGraphqlError

        err?.errors && err?.errors.forEach((err: any) => {
          msg += ": " + err.message
        })
      }
      console.log('Error adding device message ', msg) //remove message after pilot, just "error adding"
      setErrorMsg('Virhe lisättäessä laitetta ' + msg)
      setShowAlert(true)
      setShowLoading(false)
    }

    //document.body.classList.remove('qrscanner') // remove the qrscanner from the body
  }

  const removeDevice = async (regId: string) => {

    setShowLoading(true)

    try {

      const res = await callGraphQL<DeleteUserDeviceMutation>(deleteUserDevice, { input: { id: regId } });

      if (res) {
        // @ts-ignore
        const newDevices = currentUser?.devices?.items ? currentUser?.devices?.items.filter(item => item?.id !== res.data?.deleteUserDevice?.id) : []

        // @ts-ignore
        const newUser = { ...currentUser }
        // @ts-ignore
        newUser.devices.items = newDevices

        dispatch("SET_CURRENT_USER", newUser)
      }

    } catch (error) {

    }

    setShowLoading(false)

  }

  // @ts-ignore
  const openScanner = async () => {
    //const { BarcodeScanner, PhotoLibrary } = Plugins

    console.log("openscanner devide = ", isPlatform.name);



    if (isPlatform('mobile') && !isPlatform('mobileweb')) {

      const hasPermission = await checkCameraPermission()
      if (!hasPermission) {
        setErrorMsg(
          'Jos halua rekisteröidä laitteen, annathan sovellukselle kameran käyttöoikeuden'
        )
        setShowAlert(true)
      }
      //const data = await BarcodeScanner.scan();

      //const res = await BarcodeScanner.hideBackground()
      document.body.classList.add('qrscanner') // add the qrscanner class to body

      setToastContent('Avataan kamera') //need to say something to cause rendering
      setShowToast(true)

      setTimeout(() => closeQRScanner(), 10000)

      /*
      const data = await BarcodeScanner.startScan({
        targetedFormats: ['QR_CODE']
      })

      await BarcodeScanner.stopScan()
      //document.body.classList.remove('qrscanner')
      //await BarcodeScanner.showBackground()

      try {
        if (data.hasContent) {
          console.log('BarcodeScanner content: ', data.content)
          const deviceObj = JSON.parse(data.text)

          if (deviceObj.type !== 'kamu') {
            throw Error('Type not Kamu')
          }

          if (!deviceObj.name) {
            throw Error('Name not defined')
          }

          handleDeviceAdd(deviceObj.name)
        }
      } catch (error) {
        setErrorMsg(error)
        setShowAlert1(true)
        //document.body.classList.remove('qrscanner')
      }
      */
    } else {
      // open modal to add device
      //document.body.classList.remove('qrscanner')
      setShowAddDeviceToUserModal(true)
      setTimeout(() => {
        setShowAddDeviceToUserModal(false)
      }, 100)
      //await BarcodeScanner.showBackground()
    }
  }

  const closeQRScanner = async () => {
    //const { BarcodeScanner } = Plugins

    await BarcodeScanner.stopScan()
    await BarcodeScanner.showBackground()
    document.body.classList.remove('qrscanner')

    setToastContent('kamera suljettu')
    setShowToast(true)
    // remove the qrscanner from the body
  }

  /*   const handleUnregClick = async () => {

      //const perm = await hasPermission()
      const perm = getToken()

      //console.lo
      // @ts-ignore: Unreachable code error
      setErrorMsg(perm)
      setShowAlert(true)
    } */

  const handleLogOutClick = async () => {
    // await Auth.signOut()
    signOut()
    // history.push("/")
  }

  const handleNotificationsOnClick = async (value: boolean) => {
    try {

      const params: UpdateUserInput = {
        id: currentUser?.id,
        notificationsOn: value
      }

      const res = await callGraphQL<UpdateUserMutation>(updateUser2, { input: params });
      // update state on successfull mutation
      const updatedUser = mapUpdateUserMutation(res);
      dispatch("SET_CURRENT_USER", updatedUser)
      setUserHelpState(updatedUser as User)
      // currentUserRef.current = updatedUser;

      // update current user's ref value. This will be used in subscription
      //const updatedUser = mapUpdateUserMutation(res);
      // console.log('Updated user:', updatedUser)
      // @ts-ignore
      //currentUserRef.current = { ...updatedUser };

    } catch (error) {
      console.log(error)
    }
  }

  // @ts-ignore
  const userDeviceItems = currentUser?.devices?.items ? currentUser?.devices?.items as UserDeviceItem[] : []

  const userDevices = () => {
    if (userDeviceItems.length === 0) {
      return "Ei laitteita"
    } else {
      // return list of devices the user is following. Also id in join table is returned
      return userDeviceItems?.sort(makeComparator("id")).map(deviceItem => {
        const { id, device } = deviceItem
        return (
          <IonCardStyled key={id} style={{ overflow: 'visible' }}>
            <IonCardHeader>
              <IonCardTitle>
                Laitenimi: {device.name}
              </IonCardTitle>
              <IonCardSubtitle>Laitetunnus: {device.id}</IonCardSubtitle>
              {/*               <IonCardSubtitle>Seurantatunnus: {id}</IonCardSubtitle> */}
            </IonCardHeader>
            <IonCardContent>
              <h3>Osoite: {device.address}</h3>
              <h3>Omistaja: {device.owner}</h3>
              <h3>Lisätiedot: {device.description}</h3>
              <h3>Laitetyyppi: {device.deviceType}</h3>
            </IonCardContent>
            <IonGrid className="ion-no-padding " style={{ padding: '5px' }}>
              <IonRow>
                <IonCol></IonCol>
                <IonCol size="auto" className="ion-no-padding ion-align-self-end">
                  <IonButton shape="round" size="small"
                    onClick={() => {
                      presentDeleteConfirmation({
                        header: "Olevtko varma?",
                        message: "Toimintoa ei voi perua.",
                        buttons: [
                          {
                            text: "Peruuta"
                          },
                          {
                            text: "Hyväksy",
                            handler: () => {
                              removeDevice(id)
                            }
                          },
                        ],
                        onDidDismiss: (e) => console.log('delete dismissed'),
                      })
                    }}>Poista</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardStyled>
        )
      });
    }

  };


  return document.body.classList.contains('qrscanner') ? (
      <CaptureQRPage
        closeScanner={closeQRScanner}
        onHandleDeviceAdd={handleDeviceAdd}
      />
  ) : (
    <PageWrapper pageName={PageNames.SETTINGS}>
      <IonListStyled inset={false} lines="inset">
        <IonGrid>

          <IonRow>
            <IonCol>
              <h2>Omat tiedot</h2>
              <IonRow>
                <IonText>Käyttäjätunnus: {user.username}</IonText>
              </IonRow>
              <IonRow>
                <IonText>Käyttäjä-id: {currentUser.id}</IonText>
              </IonRow>
              <IonRow>
                <IonText>Puhelinnumero: {user?.attributes?.phone_number}</IonText>
              </IonRow>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <h2>Asetukset</h2>
              <IonList>
                <IonItem className="ion-no-padding" style={{ background: 'red', display: 'flex', width: '100%' }}>
                  <IonLabel>Push notifikaatiot</IonLabel>
                  <IonToggle style={{ marginRight: '-1rem' }} slot="end" onClick={() => handleNotificationsOnClick(!currentUser?.notificationsOn)} checked={currentUser?.notificationsOn}></IonToggle>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <h2>Laitteet</h2>
              <IonList style={{ backgroundColor: '#ffffff00' }}>
                {userDevices()}
              </IonList>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonButton shape="round" onClick={handleLogOutClick} href="/">Kirjaudu ulos</IonButton>
          </IonRow>
        </IonGrid>
      </IonListStyled>

      <AddDeviceToUserModal openModal={showAddDeviceToUserModal} />

      <IonFab vertical='bottom' horizontal='end' slot='fixed'>
        <IonFabButton onClick={openScanner}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Virhe!"}
        message={errorMsg}
        buttons={["OK"]}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Odota..."}
        duration={5000}
      />
    </PageWrapper>
  );
};

export default Settings;

type UserDeviceItem = {
  id: string
  device: Device
}
/* interface CheckboxChangeEventDetail {
  value: any;
  checked: boolean;
} */

const IonCardStyled = styled(IonCard)`
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
`


function mapUpdateByUserIdSubscription(updateUserSubscribtion: OnUpdateByUserIdSubscription): User {
  const newUser = updateUserSubscribtion.onUpdateByUserId
  return { ...newUser } as User || {}
}

/*
function mapGetUserQuery(getUserQuery: GraphQLResult<GetUserQuery>): User {
  return { ...getUserQuery.data?.getUser } as User || {}
}*/

/*
function mapUpdateUserMutation(updateUserMutation: GraphQLResult<UpdateUserMutation>): User {
  return { ...updateUserMutation.data?.updateUser } as User || {}
}*/

export interface SubscriptionValue<T> {
  value: { data: T }
}

export type GeneralGraphqlError = {
  data: {} | null
  errors: [graphqlError] | null
}

type graphqlError = {
  message: string
  errorType: string
}


const IonListStyled = styled(IonList)`
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0,0,0,0);
  max-width: 60rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`
