import React, { useEffect, useRef } from 'react';
import { IonButton, IonButtons, IonLabel, IonList, IonListHeader, IonSearchbar, IonToggle, useIonViewWillEnter, useIonViewWillLeave, IonItem, IonGrid, IonRow, IonCol, useIonLoading, IonRefresher, IonRefresherContent } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Events.css';
import { Device } from '../models';
import { useStore } from '../store/store';
import callGraphQL, { subscribeGraphQL2 } from '../models/graphql-api';
import { GetDeviceQuery, Event, OnEventCreateOrUpdateByGroupSubscription, CreateEventMutationVariables, eventStatus, eventClasses, User } from '../API';
import { getDevice } from '../graphql/queries';
import { GraphQLResult } from "@aws-amplify/api";
import EventItem from '../components/EventItem'
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { mapOnEventCreateOrUpdateSubscriptionResponse } from '../graphql/queryMapping';
import { EventIncDevice } from '../types/kamuTypes';
import { onEventCreateOrUpdateByGroup } from '../graphql/subscriptions';
import PageBanner from '../components/PageBanner';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';

import { useAppDispatch, useAppSelector, useResultToast } from '../hooks/hooks';
import { addEvent, addEventToOnlyState, fetchEvents, GeneralGraphqlError, updateEventInStateOnly } from '../state/eventsSlice';
import { fetchUser } from '../state/userSlice';
import useSound from 'use-sound';

import chimeSound from '../assets/audio/soft-alarm.mp3';
import { RefresherEventDetail } from '@ionic/core';
import { PageNames, PageWrapper } from '../components/PageWrapper';

declare module globalThis {
  let eventSubscription: any;
  let groupEventSubscription: any;
}

const Events: React.FC = () => {

  const [showLoader, dismiss] = useIonLoading();

  const resultToast = useResultToast();

  const [play] = useSound(chimeSound);

  // redux store
  const { Events } = useAppSelector((state) => state.events)
  const { user } = useAppSelector((state) => state.user)
  const dispatch2 = useAppDispatch()

  // custom store
  const [state, dispatch] = useStore(true)
  const { eventFilter } = state

  const eventsRef = useRef<EventIncDevice[] | []>(Events);

  const currentUserRef = useRef(user)

  const playSoundRef = useRef(play)

  const { searchText, showOnlyActive } = eventFilter

  const checkUser = async () => {
    if (!user) {
      try {
        // get current user's cognito id
        const authUser = await Auth.currentAuthenticatedUser()

        const res = await dispatch2(fetchUser({ id: authUser.attributes.sub }))
        return res.payload as User
      } catch (error) {
        console.log(error)
        return false
      }
    } else {
      return user
    }
  }

  useEffect(() => {
    eventsRef.current = Events
    currentUserRef.current = user
    playSoundRef.current = play
  })


  const subscripeEventHandler = async (eventRawResponse: Event) => {

    console.log('raw', eventRawResponse)

    try {
      const eventResponse = mapOnEventCreateOrUpdateSubscriptionResponse(eventRawResponse)
      const { ackUser, freeForm, deviceID, reservedUntil } = eventResponse

      // current user null -> get user
      if (!currentUserRef.current) {
        await checkUser()
      }

      // if not admin, get devices followed by user and filter events by them
      const userDeviceItems = currentUserRef.current?.devices?.items

      if (userDeviceItems) {
        const deviceIds = userDeviceItems.map(item => item?.deviceId)

        console.log('user follow devices', deviceIds)

        if (!deviceIds.includes(deviceID)) {
          // user not listening publishing device
          return
        }

        // new event created. Place on top of view
        if (!ackUser && !freeForm && !reservedUntil) {
          // play sound
          if (playSoundRef.current) {
            playSoundRef.current()
          }
          await dispatch2(addEventToOnlyState(eventResponse))
        } else {
          await dispatch2(updateEventInStateOnly(eventResponse))
          console.log('event updated', eventResponse)
        }
      }

    } catch (error) {
      console.log(error)
    }

  }

  const checkSubscriptionValidity = async () => {
    // NOTE! GROUP SUBSCRIPTION LISTENS FOR DEVICES IN GROUP. THIS IS ONLY FOR GROUP ADMIN (TO DO)
    // subscribe for group event changes if not subscribed
    console.log('Checking subscribtion status...')
    console.log('Subscriping user: ', user?.username)
    if ((!globalThis.groupEventSubscription || globalThis.groupEventSubscription._state === "closed")) {
      // subscribe for changes
      console.log('subscriping group events...')

      globalThis.groupEventSubscription = await subscribeGraphQL2<OnEventCreateOrUpdateByGroupSubscription>(
        onEventCreateOrUpdateByGroup,
        { groupID: 'jyränkölä' },
        (eventCreateOrUpdateByGroupSubscription: OnEventCreateOrUpdateByGroupSubscription) => {

          console.log("... eventCreateOrUpdateByGroupSubscription ", eventCreateOrUpdateByGroupSubscription)
          subscripeEventHandler(eventCreateOrUpdateByGroupSubscription.onEventCreateOrUpdateByGroup as Event)
        })
      console.log('after subscripping group events...')
      return true
    } else {
      console.log('ALREADY subscribed to events...', globalThis.groupEventSubscription)
      return true
    }
  }

  useIonViewWillEnter(async () => {
    // get events on load
    showLoader('Ladataan', 3000, 'dots')

    const user = await checkUser()

    // get current user's events
    if (user && user.id) {
      await dispatch2(fetchEvents({ id: user.id }))
    }

    // dismiss loader
    dismiss()

    // subscribtion
    await checkSubscriptionValidity()

    // add network listener
    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        resultToast('Verkkoyhteys palautettu', "success")
        // subscribe again
        checkSubscriptionValidity()
      } else {
        resultToast('Verkkoyhteys katkennut', "danger")
      }
      console.log('Network status changed', status);
    });

    // app listener
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        checkSubscriptionValidity()
      }
      console.log('App state changed. Is active?', isActive);
    });

  },[App, Network])

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    // get events on load
    const user = await checkUser()

    // get current user's events
    if (user && user.id) {
      await dispatch2(fetchEvents({ id: user.id }))
    }

    event.detail.complete();

    // subscribtion
    await checkSubscriptionValidity()

    // add network listener
    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        resultToast('Verkkoyhteys palautettu', "success")
        // subscribe again
        checkSubscriptionValidity()
      } else {
        resultToast('Verkkoyhteys katkennut', "danger")
      }
      console.log('Network status changed', status);
    });

    // app listener
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        checkSubscriptionValidity()
      }
      console.log('App state changed. Is active?', isActive);
    });
  }

  useIonViewWillLeave(async () => {
    try {
      App.removeAllListeners()

      console.log('globalThis:', globalThis)

      // unsubscribe group events
      if (globalThis.groupEventSubscription && globalThis.groupEventSubscription._state !== "closed") {
        console.log('unsubscriping events...')
        await globalThis.groupEventSubscription.unsubscribe();
        console.log('done', globalThis.groupEventSubscription)
        globalThis.groupEventSubscription = null;
      }

      window.onbeforeunload = null;
    } catch (error) {

    }

    // remove network listener
    try {
      await Network.removeAllListeners()
      await App.removeAllListeners()
    } catch (error) {
      console.log(error)
    }

  },[App, Network])


  function mapGetDeviceQuery(getDeviceQuery: GraphQLResult<GetDeviceQuery>): Device {
    return { ...getDeviceQuery.data?.getDevice } as Device || {}
  }

  const addTestEvent = async () => {

    try {
      // get device object to attach
      const resDevice = await callGraphQL<GetDeviceQuery>(getDevice, { id: "voka1" }); //pilotti100
      const device = mapGetDeviceQuery(resDevice)
      console.log("send test for ", device.id)
      const newEvent: CreateEventMutationVariables = {
        input: {
          content: 'Äänikutsu',
          eventID: 'kkfldfff',
          data: JSON.stringify({ "eka": 1, "toka": "moikka" }),
          status: eventStatus.ALARM_ACTIVE,
          predClass: eventClasses.VOICE_CALL,
          groupID: 'jyränkölä',
          deviceID: device.id
        }
      }

      await dispatch2(addEvent(newEvent)).unwrap()
      // resultToast('Testihälytys lisätty', "success")
    } catch (error) {
      const err: GeneralGraphqlError = error as GeneralGraphqlError
      if (err) {
        const msg = err.errors?.[0].message ? err.errors?.[0].message : ''
        resultToast(msg, "danger")
      }
    }

  }

  const searchTextLowercase = searchText.toLowerCase()

  const eventList = Events
    .filter(event => searchText.length === 0 || event.location?.toLowerCase().includes(searchTextLowercase) || event.name?.toLowerCase().includes(searchTextLowercase) || event.ackUser?.username?.toLowerCase().includes(searchTextLowercase) || event.freeForm?.toLowerCase().includes(searchTextLowercase))
    .filter(event => !showOnlyActive || event.ackUser === null)
    .map(event => <EventItem key={event.id} event={event} currentUserId={user?.id} />)

  return (
    <PageWrapper pageName={PageNames.EVENTS}>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {Events.length === 0 ?
        <ExploreContainer empty={true} name="Ei tapahtumia.
         Lisää laite asetuksissa..." />
        :
        <IonListStyled inset={false} lines="inset">

          <IonListHeader style={{ paddingLeft: 0, marginBottom: '1rem' }}>
            <IonGrid>
              <IonRow >
                <IonCol size="12" sizeMd="">
                  <IonSearchbar
                    placeholder="Etsi tapahtumia"
                    value={searchText}
                    onIonChange={e => dispatch('SET_EVENT_FILTER', { ...eventFilter, searchText: e.detail.value })}
                  />
                </IonCol>
                <IonCol size="12" sizeMd="auto" style={{ display: 'flex', alignItems: 'center' }}>
                  <IonItem lines="none" className="event-search-component" style={{ width: '50%' }}>
                    <IonLabel>Näytä vain kuittaamattomat</IonLabel>
                    <IonToggle
                      slot="end"
                      style={{ marginRight: '-0.5rem' }}
                      checked={showOnlyActive}
                      onClick={e => dispatch('SET_EVENT_FILTER', { ...eventFilter, showOnlyActive: !showOnlyActive })}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonListHeader>
          {eventList.length > 0 ? eventList : <PageBanner empty={true} name="Ei tapahtumia..." />}
        </IonListStyled>
      }

      {user && (user.username?.includes("@heinolasoftwares.fi") || user.username?.includes("@aisoft.fi") || user.username?.includes("miika.jukakoski")) &&
        <IonButtons>
          <IonButton onClick={addTestEvent}>Testi</IonButton>
        </IonButtons>
      }
    </PageWrapper>
  );
};

export default Events;

const IonListStyled = styled(IonList)`
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0,0,0,0);
  max-width: 60rem;
`
