/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startReservationHandlerStepFunction = /* GraphQL */ `
  mutation StartReservationHandlerStepFunction(
    $deviceID: String!
    $eventID: String!
    $releaseDT: AWSDateTime!
  ) {
    startReservationHandlerStepFunction(
      deviceID: $deviceID
      eventID: $eventID
      releaseDT: $releaseDT
    ) {
      statusCode
      body {
        executionArn
        startDate
      }
    }
  }
`;
export const abortStepFunction = /* GraphQL */ `
  mutation AbortStepFunction($stepFunctionArn: String!) {
    abortStepFunction(stepFunctionArn: $stepFunctionArn) {
      statusCode
      body {
        stopDate
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      fcmId
      notificationsOn
      status
      admin
      groupIDs
      createdAt
      updatedAt
      devices {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      deviceType
      name
      description
      status
      alarmStatus
      owner
      ownerId
      pairingActive
      address
      groupID
      createdAt
      updatedAt
      followers {
        items {
          id
          userId
          deviceId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          eventID
          status
          predClass
          targetClass
          ackTime
          ackUserId
          data
          content
          freeForm
          deviceID
          groupID
          reservedByUserId
          reservedUntil
          stepFunctionID
          geoLocation
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice(
    $input: UpdateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    updateUserDevice(input: $input, condition: $condition) {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      id
      userId
      deviceId
      createdAt
      updatedAt
      user {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      eventID
      status
      predClass
      targetClass
      ackTime
      ackUserId
      data
      content
      freeForm
      deviceID
      groupID
      reservedByUserId
      reservedUntil
      stepFunctionID
      geoLocation
      createdAt
      updatedAt
      ackUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      reservedByUser {
        id
        username
        fcmId
        notificationsOn
        status
        admin
        groupIDs
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      device {
        id
        deviceType
        name
        description
        status
        alarmStatus
        owner
        ownerId
        pairingActive
        address
        groupID
        createdAt
        updatedAt
        followers {
          nextToken
        }
        events {
          nextToken
        }
      }
    }
  }
`;
