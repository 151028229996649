import React from 'react';
import './PageBanner.css';
import { motion } from "framer-motion"
import { IonImg } from '@ionic/react';
import styled from 'styled-components';


interface ContainerProps {
  name: string;
  empty: boolean;
}

const BannerImage = styled(IonImg)`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
`

const PageBanner: React.FC<ContainerProps> = ({ empty = false, name }) => {
  return <div className="container">
    {empty && <div >
      <motion.div animate={{ opacity: true ? 1 : 0 }} />
      <strong>{name}</strong>
      <div className="containerIcon">
        <BannerImage src="/assets/img/grandma-animate.svg" />
      </div>
    </div>}
    
  </div>
};

export default PageBanner;
