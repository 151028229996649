

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React, { } from 'react'
import './PageWrapper.css'

export const PageWrapper: React.FC<PageProps> = ({ pageName, children }) => {

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>{pageName}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{pageName}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {children}
            </IonContent>
        </IonPage>
    )
}

type PageProps = {
    pageName: PageNames
}

export enum PageNames {
    EVENTS = "Tapahtumat",
    SETTINGS = "Omat asetukset",
    DEVICES = "Laitehallinta"
}
