import { IonBadge } from '@ionic/react'
import React from 'react'
import { useAppSelector } from '../hooks/hooks'

const EventsTabBadge = () => {

    const { Events } = useAppSelector((state) => state.events)

    const unAckEventCnt = Events.filter(event => !event.ackUser).length

    return unAckEventCnt > 0 ? <IonBadge color="secondary">{unAckEventCnt}</IonBadge> : null
}

export default EventsTabBadge
