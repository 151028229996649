import React from 'react';
import './ExploreContainer.css';
import { motion } from "framer-motion"
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
interface ContainerProps {
  name: string;
  empty: boolean;
}

const SignInImage = styled(IonImg)`
    margin-top: 20px;
    width: 400px;
`

const ExploreContainer: React.FC<ContainerProps> = ({ empty = false, name }) => {
  return <div className="container" style={{top: '50px'}}>
    {empty &&
      <div className="container">
        <motion.div animate={{ opacity: true ? 1 : 0 }} />
        <div className="containerIcon">
          <SignInImage src="/assets/img/online-doctor-animate.svg" />
        </div>
        <p>{name}</p>
      </div>
    }
  </div>
};

export default ExploreContainer;
