import { Translations } from '@aws-amplify/ui-components';

const amplifyFi = {
    [Translations.SIGN_IN_HEADER_TEXT]: "Kirjaudu sisään",
    [Translations.SIGN_IN_ACTION]: "Kirjaudu",
    [Translations.CREATE_ACCOUNT_TEXT]: "Luo tili",
    [Translations.FORGOT_PASSWORD_TEXT]: "Salasana unohtunut?",
    [Translations.RESET_PASSWORD_TEXT]: "Nollaa salasana",
    [Translations.RESET_YOUR_PASSWORD]: "Nollaa salasanasi",
    [Translations.PASSWORD_LABEL]: "Salasana",
    [Translations.PASSWORD_PLACEHOLDER]: "Syötä salasana",
    [Translations.BACK_TO_SIGN_IN]: 'Takaisin kirjautumiseen',
    [Translations.SEND_CODE]: "Lähetä koodi",
    [Translations.USERNAME_LABEL]: "Käyttäjätunnus",
    [Translations.USERNAME_PLACEHOLDER]: "Syötä käyttäjätunnus",
    [Translations.NO_ACCOUNT_TEXT]: "Ei käyttäjätiliä?",
    [Translations.EMAIL_LABEL]: "Sähköpostiosoite",
    [Translations.EMAIL_PLACEHOLDER]: "Syötä sähköpostiosoite",
    [Translations.EMPTY_USERNAME]: "Käyttäjätunnus ei voi olla tyhjä",
    [Translations.EMPTY_PASSWORD]: "Salasana ei voi olla tyhjä",
    [Translations.EMPTY_CODE]: "Koodi ei voi olla tyhjä",
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "Syötä sähköpostiosoite",
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "Syötä käyttäjätunnus",
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "Syötä salasana",
    [Translations.SIGN_UP_HEADER_TEXT]: "Luo käyttäjätili",
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Luo tili",
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Sinulla on jo tili?",
    [Translations.SIGN_IN_TEXT]: "Kirjaudu sisään",
    [Translations.PHONE_LABEL]: "Puhelinnumero",
    [Translations.PHONE_PLACEHOLDER]: "",
    [Translations.SIGN_OUT]: "Kirjaudu ulos",
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Vahvistuskoodi",
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Vahvista tilin luonti",
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Koodi hukassa?",
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Lähetä uudelleen",
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Vahvista",
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Syötä koodi",
    [Translations.CONFIRM]: "Vahvista",
    // [Translations.CONFIRM]: "Käyttäjä on jo olemassa",
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: "Sähköposti",
    [Translations.VERIFY_CONTACT_HEADER_TEXT]: "Jotta unohtuneen salasanan voi palauttaa, tulee sinun todentaa sähköpostisi",
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: "Todenna",
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: "Lähetä",
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: "Puhelinnumero",
    [Translations.CODE_PLACEHOLDER]: "Syötä koodi",
    [Translations.SIGN_IN_WITH_FACEBOOK]: "Kirjaudu Facebookilla",
    [Translations.SIGN_IN_WITH_GOOGLE]: "Kirjaudu Googlella",
    "Confirm Password": "Vahvista salasana",
    "Enter your email": "Syötä sähköpostiosoite",
    "Send code": "Nollaa salasana",
    "Code": "Koodi",
    "New password": "Uusi salasana",
    "Your passwords must match": "Salasanojen pitää olla samat",
    "User already exists": "Käyttäjä on jo olemassa",
    "Confirm Sign Up": "Vahvista rekisteröinti",
    "Skip": "Ohita",
    "Signing in": "Kirjaudutaan sisään"

}


export { amplifyFi }