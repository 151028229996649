// @ts-nocheck

import { useEffect, useState } from "react";
import { isPlatform } from "@ionic/react";
import { Auth } from "aws-amplify";
import "firebase/messaging";

import { FCM } from "@capacitor-community/fcm";
import {
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";

import { useAppDispatch } from "./hooks";
import { fetchEvents } from "../state/eventsSlice";

//let pushObj = {};
// let token: string;
// let listeners = [];
// let actions = [];

//const usePush = (/*onNewNotification: (NotificationMessage) => void */) => {

const isPushNotificationsAvailable =
  Capacitor.isPluginAvailable("PushNotifications");

const usePush = () => {
  //const setPush = useState(pushObj)[1];
  //const setToken = useState(token)[1];
  const [token, setToken] = useState("");
  const [notification, setNotification] = useState<NotificationMessage>(null);
  const [userAction, setUserAction] = useState("");
  const [pushPermissionsGranted, setPushPermissionsGranted] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const init = () => {
    console.log("pushNotification init ");

    PushNotifications.register();

    PushNotifications.addListener("registration", (token: Token) => {
      console.log(
        "pushNotification CAP PUSH Token registered (registration callback) ",
        JSON.stringify(token),
        token.value
      );
      // this doesn't work on IOS, and
      // crashes android

      if (isPlatform("android")) {
        setToken(token.value);
        /*FCM.getToken()
          .then((result) => {
            //this.remoteToken = result.token;
            console.log(
              `pushNotification FCM Token registered (callback) ${result.token}`
            );
            setToken(result.token);
          })
          .catch((err) => console.log("i am Error", err));*/
      }
    });

    // Some issue with your setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      alert("pushNotification Error on registration: " + JSON.stringify(error));
    });

    /*
    PushNotifications.register().then(() => {
      console.log("pushNotification callback after Push registration... ");
    });

    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        console.log("Push registration token: ", token.value);
        //setToken(token.value);
      }
    );*/

    //
    // Get FCM token instead the APN one returned by Capacitor
    // this works on IOS, but no notifications on android
    if (isPlatform("ios")) {
      FCM.getToken()
        .then((r) => {
          console.log(`pushNotification FCM Token ${r.token}`);
          //alert(`FCM Token ${r.token}`);
          setToken(r.token);
        })
        .catch((err) => console.log(err));
    }

    // Enable the auto initialization of the library
    //FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

    // Check the auto initialization status
    /*FCM.isAutoInitEnabled().then((r) => {
      console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });*/

    //FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

    // Show us the notification payload if the app is open on our device

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotification) => {
        console.log("pushNotification Received ", JSON.stringify(notification));

        const notif: NotificationMessage = {
          title: notification.title,
          body: notification.body,
        };
        //instead of callback, use getNotification hook

        //alert(notif);

        setNotification(notif);
      }
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (event: LocalNotificationActionPerformed) => {
        console.log(
          "pushNotification localNotificationActionPerformed ",
          event
        );
        //alert("localNotificationActionPerformed ", event)
        // avataanko suoraan Device näkymä, jossa lista eventeistä?
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: PushNotificationActionPerformed) => {
        console.log(
          "pushNotificationActionPerformed  actionId",
          notification.actionId
        );
        //alert("pushNotificationActionPerformed ", notification);

        setUserAction(notification.actionId);

        // get current user's cognito id
        const authUser = await Auth.currentAuthenticatedUser();

        // fetch events
        if (authUser && authUser.attributes.sub) {
          await dispatch(fetchEvents({ id: authUser.attributes.sub }));
        }
      }
    );
  };

  useEffect(() => {
    if (isPlatform("capacitor")) {
      console.log("pushNotifications checkPermissions called ");
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          console.log("pushNotifications requestPermissions call ");
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === "denied") {
              console.log("PushNotifications permission denied");
              setPushPermissionsGranted(false);
            } else {
              console.log("PushNotifications permission granted");
              setPushPermissionsGranted(true);
            }
          });
        } else {
          console.log("PushNotifications permission already granted");
          setPushPermissionsGranted(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const pushInit = () => {
      if (isPushNotificationsAvailable) {
        init();
      }
    };

    console.log(
      "PushNotifications isPushNotificationsAvailable ",
      isPushNotificationsAvailable
    );

    if (isPlatform("capacitor")) {
      if (pushPermissionsGranted) {
        // init notifications
        pushInit();
      } else {
        requestPermissions().then((res) => {
          console.log("requestPermissions response : ", res);
          if (pushPermissionsGranted) {
            // init notifications if permission created
            pushInit();
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pushPermissionsGranted]);

  const checkPermissions = () => {
    console.log(
      "PushNotifications hasPermissions called!",
      isPushNotificationsAvailable
    );

    return new Promise((resolve, reject) => {
      if (isPlatform("capacitor")) {
        return PushNotifications.checkPermissions(
          (status: PermissionStatus) => {
            console.log("PushNotifications checkPermission status ", status);
            if (status.isEnabled) {
              console.log("PushNotifications isEnabled");
              setPushPermissionsGranted(true);
              resolve(status);
            } else {
              console.log("PushNotifications DISABLED");
              setPushPermissionsGranted(false);
              reject("PushNotifications DISABLED");
            }
          }
        );
      } else {
        console.log("PushNotifications hasPermissions WEB ");
        reject("not on the web");
      }
    });
  };

  const requestPermissions = () => {
    return new Promise((resolve, reject) => {
      if (isPlatform("android") || isPlatform("ios")) {
        PushNotifications.requestPermissions((status: PermissionStatus) => {
          if (status.isEnabled) {
            console.log("PushNotifications isEnabled");
            setPushPermissionsGranted(true);
            resolve(status);
          } else {
            console.log("PushNotifications DISABLED");
            setPushPermissionsGranted(false);
            reject("PushNotifications DISABLED");
          }
        });
      } else {
        reject("not on the web");
      }
    });
  };

  const getToken = () => {
    return token;
  };

  // get the last notification received
  const getNotification = () => {
    return notification;
  };

  const setNotificationUserAction = (action) => {
    setUserAction(action);
  };

  const getNotificationUserAction = () => {
    return userAction;
  };

  return {
    init,
    //register,
    checkPermissions,
    requestPermissions,
    getToken,
    getNotification,
    getNotificationUserAction,
    setNotificationUserAction,
  };
};

export interface NotificationMessage {
  title: string;
  body: string;
}

export default usePush;
