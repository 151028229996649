import React from 'react'
import {
  useIonLoading,
  useIonViewWillEnter
} from '@ionic/react'
import './Manage.css'
import DeviceManageListing from '../components/DeviceManageListing'
import AddDeviceModal from '../modals/AddDeviceModal'
import callGraphQL from '../models/graphql-api'
import { ListDevicesQuery } from '../API'
import { listDevices } from '../graphql/queries'
import { useStore } from '../store/store'
import { mapListDevicesQuery } from '../graphql/queryMapping'
import { PageNames, PageWrapper } from '../components/PageWrapper'

const Manage: React.FC = () => {
  const dispatch = useStore(true)[1]

  const [showLoader, dismiss] = useIonLoading();

  useIonViewWillEnter(async () => {

    showLoader('Ladataan', 3000, 'dots')

    // get devices on load
    const res = await callGraphQL<ListDevicesQuery>(listDevices)
    const deviceItems = mapListDevicesQuery(res)
    dispatch('SET_DEVICES', deviceItems)

    dismiss()
  })

  return (
    <PageWrapper pageName={PageNames.DEVICES}>
      <DeviceManageListing />
      <AddDeviceModal />
    </PageWrapper>
  )
}

export default Manage
