import { IonList } from "@ionic/react";
import React from "react";
import { makeComparator } from "../utils/utils";
import DeviceManageItem from "./DeviceManageItem";
import ExploreContainer from "./ExploreContainer";
import { useStore } from "../store/store";
import styled from 'styled-components';

export default function DeviceManageListing() {

  const state = useStore(true)[0]

  const { devices } = state

  const deviceItems = () => {
    return devices.sort(makeComparator("name")).map(device => (
      <DeviceManageItem device={device} key={device.id} />
    ));
  };

  if (devices.length === 0) {
    return <ExploreContainer empty={true} name="Ei laitteita. Lisää uusi..." />
  } else {
    return <IonListStyled>{deviceItems()}</IonListStyled>
  }

};


const IonListStyled = styled(IonList)`
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0,0,0,0);
  max-width: 60rem;
`